import React, { useState, useEffect, useRef } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import '../../Client-management/printFile.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const EmployeeSalaryPrint = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ledger, setLedger] = useState([]);
    const [plot, setPlot] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const response = await Axios.get(`https://backend.pgechs.online/employee/all`);
                if (response.status === 200) {
                    const { data } = response;
                    if (data.data != null) {
                       
                        
                        // Use the MemberID as the key to store member details
                        console.log(data);
                        setLedger(data.data);
                    } else {
                        console.log(data.msg);
                    }
                } else {
                    console.log("No data found from API");
                }
            } catch (error) {
                console.error("Error fetching data from API:", error);
            } 
        };
        const fetchAllEmployeeSalaries = async (ledger) => {
            const allSalaries = {};
            for (const item of ledger) {
                try {
                    const response = await Axios.get(`https://backend.pgechs.online/employeeSalary/all/${item._id}`);
                    if (response.status === 200 && response.data.data) {
                        allSalaries[item._id] = response.data.data;
                    }
                } catch (error) {
                    console.error("Error fetching data from API:", error);
                }
            }
            return allSalaries;
        };
        const fetchData = async () => {
            const salaries = await fetchAllEmployeeSalaries(ledger);
            setEmployeeSalaries(salaries);
            setIsLoading(false);
        };

        // Call the function to fetch member data
        fetchMemberData();
        fetchData();
    }, [ledger]);
    const [employeeSalaries, setEmployeeSalaries] = useState({});
    
    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
    };
    const handleDownloadPDF = () => {
        window.print();
    };
    let srNo = 0;
    let sumOfDebit = 0;
    let month1 = 0;
    let month2 = 0;
    let month3 = 0;
    let month4 = 0;
    let month5 = 0;
    let month6 = 0;
    let month7 = 0;
    let month8 = 0;
    let month9 = 0;
    let month10 = 0;
    let month11 = 0;
    let month12 = 0;
    let monthTotal1 = 0;
    let monthTotal2 = 0;
    let monthTotal3 = 0;
    let monthTotal4 = 0;
    let monthTotal5 = 0;
    let monthTotal6 = 0;
    let monthTotal7 = 0;
    let monthTotal8 = 0;
    let monthTotal9 = 0;
    let monthTotal10 = 0;
    let monthTotal11 = 0;
    let monthTotal12 = 0;
    let totalSalary = 0;
    let grandTotalSalary = 0;
    let flag = true;
    
    let remainingBalance = 0;
    function DateComponent({ dateString }) {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date
        const formattedDate = `${year}-${month}-${day}`;
        return <div>{formattedDate}</div>;
    }
    const MonthComponent = (dateString) =>  {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date   
        return month;
    }
    const [nameOfEmployee, setNameOfEmployee] = useState([]);
    const [a, setA] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };
    return (
      <>
       {isLoading=== true ? (<h1>Please wait data is loading</h1>): (
            <div id="divToPrint" className="card">
            <br></br>
            <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>The Pakistan Government Employees Co-operative Housing Society Ltd.</h2>
            <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Tax Year 2023-2024</h2>
            <h4 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Detail of Salary</h4>
            <div style={{display:'flex'}}>
      <label>
        1 Month
        <input
          type="radio"
          value="option1"
          checked={selectedOption === 'option1'}
          onChange={() => handleRadioChange('option1')}
        />
       
      </label>

      <label>
      Quarter
        <input
          type="radio"
          value="option2"
          checked={selectedOption === 'option2'}
          onChange={() => handleRadioChange('option2')}
        />
        
      </label>

      <label>
      Half-Year
        <input
          type="radio"
          value="option3"
          checked={selectedOption === 'option3'}
          onChange={() => handleRadioChange('option3')}
        />
        
      </label>

      <label>
      Yearly
        <input
          type="radio"
          value="option4"
          checked={selectedOption === 'option4'}
          onChange={() => handleRadioChange('option4')}
        />
        
      </label>
    </div>        
           {selectedOption === 'option4' ? 
           <table border="2">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Date of Appointed</th>
                        <th>Jul-23</th>
                        <th>Aug-23</th>
                        <th>Sep-23</th>
                        <th>Oct-23</th>
                        <th>Nov-23</th>
                        <th>Dec-23</th>
                        <th>Jan-24</th>
                        <th>Feb-24</th>
                        <th>Mar-24</th>
                        <th>Apr-24</th>
                        <th>May-24</th>
                        <th>Jun-24</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger && ledger.map((item, index) => {
                        // Save the 'credit' value from a specific index (e.g., index 0)
                        srNo++;
                        const salariesForCurrentEmployee = employeeSalaries[item._id] || [];
                        console.log(salariesForCurrentEmployee);
                        if (true) {
                           
                            totalSalary = 0;
                            month1 = 0; month2 = 0;
                            month3 = 0;
                            month4 = 0;
                            month5 = 0;
                            month6 = 0; month7 = 0; month8 = 0;
                            month9 = 0; month10 = 0; month11 = 0; month12 = 0
                            {salariesForCurrentEmployee && salariesForCurrentEmployee.map((items, index) => {
                                // Save the 'credit' value from a specific index (e.g., index 0)

                                    if (MonthComponent(items.dateOfSalary) === "01") {
                                        month1 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "02") {
                                        month2 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "03") {
                                        month3 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "04") {
                                        month4 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "05") {
                                        month5 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "06") {
                                        month6 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "07") {
                                        month7 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "08") {
                                        month8 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "09") {
                                        month9 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "10") {
                                        month10 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "11") {
                                        month11 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "12") {
                                        month12 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    }
                            
                                
                            })}
                            monthTotal1 = monthTotal1 + month1;
                            monthTotal2 = monthTotal2 + month2;
                            monthTotal3 = monthTotal3 + month3;
                            monthTotal4 = monthTotal4 + month4;
                            monthTotal5 = monthTotal5 + month5;
                            monthTotal6 = monthTotal6 + month6;
                            monthTotal7 = monthTotal7 + month7;
                            monthTotal8 = monthTotal8 + month8;
                            monthTotal9 = monthTotal9 + month9;
                            monthTotal10 = monthTotal10 + month10;
                            monthTotal11 = monthTotal11 + month11;
                            monthTotal12 = monthTotal12 + month12;
                            grandTotalSalary = grandTotalSalary + totalSalary;
                        }
                        return (
                            <tr key={index}>
                                <td>{srNo}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                                <td><DateComponent dateString={item.dateOfAppointment} /></td>
                                <td>{month7}</td>
                                <td>{month8}</td>
                                <td>{month9}</td>
                                <td>{month10}</td>
                                <td>{month11}</td>
                                <td>{month12}</td>
                                <td>{month1}</td>
                                <td>{month2}</td>
                                <td>{month3}</td>
                                <td>{month4}</td>
                                <td>{month5}</td>
                                <td>{month6}</td>
                                <td>{totalSalary}</td>
                               
                            </tr>
                        ); 
                    })}
                </tbody>
                <tfoot>
             <tr>
               <td>Total:</td>
               <td></td>
               <td></td>
               <td></td>
               <td>{monthTotal7}</td>
               <td>{monthTotal8}</td>
               <td>{monthTotal9}</td>
               <td>{monthTotal10}</td>
               <td>{monthTotal11}</td>
               <td>{monthTotal12}</td>
               <td>{monthTotal1}</td>
               <td>{monthTotal2}</td>
               <td>{monthTotal3}</td>
               <td>{monthTotal4}</td>
               <td>{monthTotal5}</td>
               <td>{monthTotal6}</td>
               <td>{grandTotalSalary}</td>
             </tr>

           </tfoot>
            </table>: null}
            {selectedOption === 'option3' ? 
           <table border="2">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Date of Appointed</th>
                        <th>Jul-23</th>
                        <th>Aug-23</th>
                        <th>Sep-23</th>
                        <th>Oct-23</th>
                        <th>Nov-23</th>
                        <th>Dec-23</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger && ledger.map((item, index) => {
                        // Save the 'credit' value from a specific index (e.g., index 0)
                        srNo++;
                        const salariesForCurrentEmployee = employeeSalaries[item._id] || [];
                        console.log(salariesForCurrentEmployee);
                        if (true) {
                           
                            totalSalary = 0;
                            month1 = 0; month2 = 0;
                            month3 = 0;
                            month4 = 0;
                            month5 = 0;
                            month6 = 0; month7 = 0; month8 = 0;
                            month9 = 0; month10 = 0; month11 = 0; month12 = 0
                            {salariesForCurrentEmployee && salariesForCurrentEmployee.map((items, index) => {
                                // Save the 'credit' value from a specific index (e.g., index 0)

                                    if (MonthComponent(items.dateOfSalary) === "01") {
                                        month1 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "02") {
                                        month2 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "03") {
                                        month3 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "04") {
                                        month4 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "05") {
                                        month5 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "06") {
                                        month6 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "07") {
                                        month7 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "08") {
                                        month8 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "09") {
                                        month9 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "10") {
                                        month10 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "11") {
                                        month11 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "12") {
                                        month12 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    }
                            
                                
                            })}
                            monthTotal1 = monthTotal1 + month1;
                            monthTotal2 = monthTotal2 + month2;
                            monthTotal3 = monthTotal3 + month3;
                            monthTotal4 = monthTotal4 + month4;
                            monthTotal5 = monthTotal5 + month5;
                            monthTotal6 = monthTotal6 + month6;
                            monthTotal7 = monthTotal7 + month7;
                            monthTotal8 = monthTotal8 + month8;
                            monthTotal9 = monthTotal9 + month9;
                            monthTotal10 = monthTotal10 + month10;
                            monthTotal11 = monthTotal11 + month11;
                            monthTotal12 = monthTotal12 + month12;
                            grandTotalSalary = grandTotalSalary + totalSalary;
                        }
                        return (
                            <tr key={index}>
                                <td>{srNo}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                                <td><DateComponent dateString={item.dateOfAppointment} /></td>
                                <td>{month7}</td>
                                <td>{month8}</td>
                                <td>{month9}</td>
                                <td>{month10}</td>
                                <td>{month11}</td>
                                <td>{month12}</td>
                                <td>{totalSalary}</td>
                               
                            </tr>
                        ); 
                    })}
                </tbody>
                <tfoot>
             <tr>
               <td>Total:</td>
               <td></td>
               <td></td>
               <td></td>
               <td>{monthTotal7}</td>
               <td>{monthTotal8}</td>
               <td>{monthTotal9}</td>
               <td>{monthTotal10}</td>
               <td>{monthTotal11}</td>
               <td>{monthTotal12}</td>
               <td>{grandTotalSalary}</td>
             </tr>

           </tfoot>
            </table>: null}
            
            {selectedOption === 'option2' ? 
           <table border="2">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Date of Appointed</th>
                        <th>Jul-23</th>
                        <th>Aug-23</th>
                        <th>Sep-23</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger && ledger.map((item, index) => {
                        // Save the 'credit' value from a specific index (e.g., index 0)
                        srNo++;
                        const salariesForCurrentEmployee = employeeSalaries[item._id] || [];
                        console.log(salariesForCurrentEmployee);
                        if (true) {
                           
                            totalSalary = 0;
                            month1 = 0; month2 = 0;
                            month3 = 0;
                            month4 = 0;
                            month5 = 0;
                            month6 = 0; month7 = 0; month8 = 0;
                            month9 = 0; month10 = 0; month11 = 0; month12 = 0
                            {salariesForCurrentEmployee && salariesForCurrentEmployee.map((items, index) => {
                                // Save the 'credit' value from a specific index (e.g., index 0)

                                    if (MonthComponent(items.dateOfSalary) === "01") {
                                        month1 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "02") {
                                        month2 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "03") {
                                        month3 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "04") {
                                        month4 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "05") {
                                        month5 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "06") {
                                        month6 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "07") {
                                        month7 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "08") {
                                        month8 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "09") {
                                        month9 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "10") {
                                        month10 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "11") {
                                        month11 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "12") {
                                        month12 = items.salary;
                                        
                                    }
                            
                                
                            })}
                            monthTotal1 = monthTotal1 + month1;
                            monthTotal2 = monthTotal2 + month2;
                            monthTotal3 = monthTotal3 + month3;
                            monthTotal4 = monthTotal4 + month4;
                            monthTotal5 = monthTotal5 + month5;
                            monthTotal6 = monthTotal6 + month6;
                            monthTotal7 = monthTotal7 + month7;
                            monthTotal8 = monthTotal8 + month8;
                            monthTotal9 = monthTotal9 + month9;
                            monthTotal10 = monthTotal10 + month10;
                            monthTotal11 = monthTotal11 + month11;
                            monthTotal12 = monthTotal12 + month12;
                            grandTotalSalary = grandTotalSalary + totalSalary;
                        }
                        return (
                            <tr key={index}>
                                <td>{srNo}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                                <td><DateComponent dateString={item.dateOfAppointment} /></td>
                                <td>{month7}</td>
                                <td>{month8}</td>
                                <td>{month9}</td>
                                <td>{totalSalary}</td>
                               
                            </tr>
                        ); 
                    })}
                </tbody>
                <tfoot>
             <tr>
               <td>Total:</td>
               <td></td>
               <td></td>
               <td></td>
               <td>{monthTotal7}</td>
               <td>{monthTotal8}</td>
               <td>{monthTotal9}</td>
               
               <td>{grandTotalSalary}</td>
             </tr>

           </tfoot>
            </table>: null}
            {selectedOption === 'option1' ? 
           <table border="2">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Date of Appointed</th>
                        <th>Jul-23</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger && ledger.map((item, index) => {
                        // Save the 'credit' value from a specific index (e.g., index 0)
                        srNo++;
                        const salariesForCurrentEmployee = employeeSalaries[item._id] || [];
                        console.log(salariesForCurrentEmployee);
                        if (true) {
                           
                            totalSalary = 0;
                            month1 = 0; month2 = 0;
                            month3 = 0;
                            month4 = 0;
                            month5 = 0;
                            month6 = 0; month7 = 0; month8 = 0;
                            month9 = 0; month10 = 0; month11 = 0; month12 = 0
                            {salariesForCurrentEmployee && salariesForCurrentEmployee.map((items, index) => {
                                // Save the 'credit' value from a specific index (e.g., index 0)

                                    if (MonthComponent(items.dateOfSalary) === "01") {
                                        month1 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "02") {
                                        month2 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "03") {
                                        month3 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "04") {
                                        month4 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "05") {
                                        month5 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "06") {
                                        month6 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "07") {
                                        month7 = items.salary;
                                        totalSalary = totalSalary+items.salary;
                                    } else if (MonthComponent(items.dateOfSalary) === "08") {
                                        month8 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "09") {
                                        month9 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "10") {
                                        month10 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "11") {
                                        month11 = items.salary;
                                        
                                    } else if (MonthComponent(items.dateOfSalary) === "12") {
                                        month12 = items.salary;
                                        
                                    }
                            
                                
                            })}
                            monthTotal1 = monthTotal1 + month1;
                            monthTotal2 = monthTotal2 + month2;
                            monthTotal3 = monthTotal3 + month3;
                            monthTotal4 = monthTotal4 + month4;
                            monthTotal5 = monthTotal5 + month5;
                            monthTotal6 = monthTotal6 + month6;
                            monthTotal7 = monthTotal7 + month7;
                            monthTotal8 = monthTotal8 + month8;
                            monthTotal9 = monthTotal9 + month9;
                            monthTotal10 = monthTotal10 + month10;
                            monthTotal11 = monthTotal11 + month11;
                            monthTotal12 = monthTotal12 + month12;
                            grandTotalSalary = grandTotalSalary + totalSalary;
                        }
                        return (
                            <tr key={index}>
                                <td>{srNo}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                                <td><DateComponent dateString={item.dateOfAppointment} /></td>
                                <td>{month7}</td>
                                <td>{totalSalary}</td>
                               
                            </tr>
                        ); 
                    })}
                </tbody>
                <tfoot>
             <tr>
               <td>Total:</td>
               <td></td>
               <td></td>
               <td></td>
               <td>{monthTotal7}</td>
               <td>{grandTotalSalary}</td>
             </tr>

           </tfoot>
            </table>: null}
            <br></br>
            <button onClick={handleDownloadPDF}>Download PDF</button>
        </div>
        )
       };
      </>
    );
};

export default EmployeeSalaryPrint;