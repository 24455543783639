import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import CompanyForm from "./CompanyForm";
import TransferOwnership from "./TransferOwnership";
import './search.css';
function CompanyData() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBy, setSearchBy] = useState('memberId'); // Default search criteria
  const [cusData, setCusData] = useState([]);
  const [displayPosition, setDisplayPosition] = useState(false);
  const [position, setPosition] = useState("center");

  const [MemberdisplayPosition, setMemberDisplayPosition] = useState(false);
  const [Memberposition, setMemberPosition] = useState("center");
  const [selectedMemberID, setMemberID] = useState();

  const [edittable, setedittable] = useState(false);
  const [editdata, seteditdata] = useState();
  const [getId, setGetId] = useState();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState('');

  // Create an object to store member details with MemberID as keys
  const [memberDetails, setMemberDetails] = useState([]);
  const [memberDetail, setMemberDetail] = useState([]);
  useEffect(() => {
    getAllCustomers();
  }, []);
  const handleSearch = async () => {

    if (searchBy === "email") {
      const results = cusData.filter(item =>
        item.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Update the searchResults state with the filtered data
      setCusData(results);
    }
    if (searchBy === "memberId") {

      const results = cusData.filter(item =>
        item.MemberId.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Update the searchResults state with the filtered data
      setCusData(results);
    }
    if (searchBy === "") {
      const response = await Axios.get("https://backend.pgechs.online/admin/getAllRegisterdMembers");
      if (response.status === 200) {
        const { data } = response;
        setCusData(data.data);
      } else {
        console.log("No data found from API");
      }
    }

    console.log(`Searching by ${searchBy}: ${searchTerm}`);
  };
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
 
    if (position) {
      setPosition(position);
    }
  };

  const CustomIcon = () => (
    // This is a hypothetical icon component, replace it with your actual icon implementation
    <span style={{ marginRight: '8px' }}>🚀</span>
  );
  
  const IconButton = ({ onClick, text }) => (
    <button onClick={onClick} style={{ display: 'flex', alignItems: 'center' , backgroundColor: 'black'}}>
      <CustomIcon />
      <span>{text}</span>
    </button>
  );
  const [showPopup, setShowPopup] = useState(false);

  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");
  const icon = (rowData) => {
    
    return (
      <div>
        {role === "6533e8c7de21620f67bef3aa" ? (
          <>
            <button className="pi pi-eye view-icon-background" onClick={() => viewMemberDetails(rowData._id)}>View</button>
            <button className="pi pi-plus-circle ml-2 p-ml-2 add-icon-background" onClick={() => addMemberDetails(rowData)}>Add</button>
            <button className="pi pi-trash ml-2 p-ml-2 delete-icon-background" onClick={() => deleteUserData(rowData)}>Delete</button>
            <button className="pi pi-arrow-circle ml-2 p-ml-2 add-icon-background" onClick={() => openPopup(rowData)}>Transfer</button>
          </>
        ) : (
          <>
            <button className="pi pi-eye ml-2 p-ml-2 view-icon-background" onClick={() => viewMemberDetails(rowData._id)}>View</button>
            <button className="pi pi-arrow-circle ml-2 p-ml-2 add-icon-background" onClick={() => openPopup(rowData)}>Transfer</button>
            <button className="pi pi-plus-circle ml-2 p-ml-2 add-icon-background" onClick={() => addMemberDetails(rowData._id)}>Add</button>
          </>
        )}
      </div>
    );
  };

  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);

    setMemberDisplayPosition(false);
    setMemberPosition(false);
  };

  const getAllCustomers = async () => {
    const response = await Axios.get("https://backend.pgechs.online/admin/getAllRegisterdMembers");
    if (response.status === 200) {
      const { data } = response;
      setCusData(data.data);
    } else {
      console.log("No data found from API");
    }
  };
  const [isPopupOpen, setPopupOpen] = useState(false);

  

  const closePopup = () => {
    setPopupOpen(false);
  };
  const confirmDelete = async () => {

    if (getApplicationStatus === true) 
    {
      if (getMemberIDforDeletion) {
        console.log("MEMBER ID: " + getMemberIDforDeletion)
        try {
          const dataResponse = await Axios.get(`https://backend.pgechs.online/members/getbyid/${getMemberIDforDeletion}`);
          if (dataResponse.status === 200) {
            const { data } = dataResponse;
            if (data.data != null) {
              console.log("subID: " + data.data._id);
              console.log(data);
              const obj = {
                email: email
              }
              const response = await Axios.delete(`https://backend.pgechs.online/members/delete/${data.data._id}`, obj);
              if (response.status === 200) {
                getAllCustomers();
              }
  
            } else {
              console.log(data.msg);
            }
          } else {
            console.log("No data found from API");
          }
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      }
    } else {
      const obj = {
        email: email
      }
      const response = await Axios.delete(`https://backend.pgechs.online/admin/memberDelete/${getMemberIDforDeletion}`, obj);
      if (response.status === 200) {
        getAllCustomers();
      }
    }
    setDeleteConfirmationVisible(false);
  }




  const [getMemberIDforDeletion, setMemberIDforDeletion] = useState(null);
  const [getApplicationStatus, setApplicationStatus] = useState(null);
  const [email, setEmail] = useState("");
  
  const deleteUserData = (rowData) => {
    setMemberIDforDeletion(rowData._id);
    setApplicationStatus(rowData.ApplicationStatus);
    setEmail(rowData.email)
    setDeleteConfirmationVisible(true);
  };

  const [membersData, setMembersData] = useState({});

  const addMemberDetails = (rowData) => {
    seteditdata(rowData);
    setMembersData(rowData._id);
    setShowPopup(false);
    onClick("displayPosition", "right");
  };

  const editfunc = (rowData) => {
    setShowPopup(false);
    setDisplayPosition(true);
    setPosition("right");
    setedittable(true);
    seteditdata(rowData);
  };

  const openPopup = (rowData) => {
    console.log(rowData);
    seteditdata(rowData);
    setPosition("right");
    
    setShowPopup(true);
    setDisplayPosition(true);
  };
  const viewMemberDetails = async (rowData) => {
    setMemberID(rowData);
  };


  const [getAllotmentCertificatePath, setAllotmentCertificatePath] = useState(null);
  const [getMemberShipTransfer, setMemberShipTransfer] = useState(null);
  const [getApplicationForm, setApplicationForm] = useState(null);
  const [getUnderTaking, setUnderTaking] = useState(null);
  const [getAffidavit, setAffidavit] = useState(null);
  const [getTransferImage, setTransferImage] = useState(null);
  const [getMergedPDF, setMergedPDF] = useState(null);
  useEffect(() => {
    if (selectedMemberID) {
      const fetchMemberData = async () => {
        try {
          const response = await Axios.get(`https://backend.pgechs.online/members/getbyid/${selectedMemberID}`);
          if (response.status === 200) {
            const { data } = response;
            if (data.data != null) {
              // Use the MemberID as the key to store member details
              console.log(data);
              setAllotmentCertificatePath(data.data.allotmentCertificate.filename);
              setMemberShipTransfer(data.data.membershipTransfer.filename);
              setApplicationForm(data.data.applicationForm.filename);
              setUnderTaking(data.data.underTaking.filename);
              setAffidavit(data.data.affidavit.filename);
              setTransferImage(data.data.transferImage.filename);
              setMergedPDF(data.data.mergedPDF.filename);
              setMemberDetails({ ...memberDetails, [selectedMemberID]: data.data });
            } else {
              console.log(data.msg);
            }
          } else {
            console.log("No data found from API");
          }
        } catch (error) {
          console.error("Error fetching data from API:", error);
        } finally {
          // Open the member details dialog
          setMemberDisplayPosition(true);
          setMemberPosition("right");
        }
      };

      // Call the function to fetch member data
      fetchMemberData();
    }
  }, [selectedMemberID]);

  const [fileToView, setFileToView] = useState(null);


  const viewFile = (fileUrl) => {
    if (fileUrl) {
      window.open(`https://backend.pgechs.online/get-file/${fileUrl}`);
    }
  };



  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button label="Cancel" className="p-button-secondary" onClick={() => setDeleteConfirmationVisible(false)} />
      <Button label="Delete" className="p-button-danger" onClick={() => confirmDelete()} />
    </div>
  );

  const columns = [
    { field: 'MemberId', header: 'Member ID' },
    { field: 'email', header: 'Email' },
    {
      field: 'ApplicationStatus',
      header: 'Status',
      body: (rowData) => {
        return rowData.ApplicationStatus ? 'Completed' : 'In-Complete';
      }
    }
  ];

  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>

      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">Member Management</h3>
            </div>

            <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder="Enter search term"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <select
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                className="search-select"
              >
                <option value="email">Email</option>
                <option value="memberId">Member ID</option>
              </select>

              <button onClick={handleSearch} className="search-button">
                Search
              </button>
            </div>
          </div>
          
          
          <Dialog
            header={edittable ? "Edit Member Details * " : "Add Member Details * "}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <CompanyForm
              getEditData={editdata}
              memberDetails={memberDetails}
              getId={getId}
              editable={edittable}
              onHide={onHide}
              memberID={membersData}
              getAllCustomers={getAllCustomers}
              showPopup={showPopup}
            />
          </Dialog>
          <Dialog
            visible={MemberdisplayPosition}
            position={Memberposition}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <div>
              {selectedMemberID && memberDetails[selectedMemberID] ? (
                <>
                  <p>Name: {memberDetails[selectedMemberID].name}</p>
                  <p>Email: {memberDetails[selectedMemberID]?.member_id?.email ?? 'N/A'}</p>
                  <p>Address: {memberDetails[selectedMemberID].address}</p>
                  <p>Phone Number: {memberDetails[selectedMemberID].phoneNumber}</p>
                  <p>CNIC: {memberDetails[selectedMemberID].cnic}</p>
                  <p>Approval Status: {memberDetails[selectedMemberID].approvalStatus ? 'Approved' : 'Pending'}</p>
                  {/* Provide clickable links to view uploaded files */}
                  {/* Conditionally render file buttons if a file URL is available */}
                  <div>
                    <p>Allotment Certificate:</p>
                    <button onClick={() => viewFile(getAllotmentCertificatePath)}>View</button>

                  </div>
                  <div>
                    <p>Membership Transfer:</p>
                    <button onClick={() => viewFile(getMemberShipTransfer)}>View</button>

                  </div>
                  <div>
                    <p>Application Form:</p>
                    <button onClick={() => viewFile(getApplicationForm)}>View</button>

                  </div>
                  <div>
                    <p>Under-taking:</p>
                    <button onClick={() => viewFile(getUnderTaking)}>View</button>

                  </div>
                  <div>
                    <p>Affidavit:</p>
                    <button onClick={() => viewFile(getAffidavit)}>View</button>

                  </div>
                  <div>
                    <p>Transfer Image:</p>
                    <button onClick={() => viewFile(getTransferImage)}>View</button>

                  </div>
                  <div>
                    <p>All Merged Documents:</p>
                    <button onClick={() => viewFile(getMergedPDF)}>View</button>

                  </div>
                  <br></br>
                  {role === "6533e8c7de21620f67bef3aa" ? (
                    <>
                      <button className="pi pi-pencil ml-2 p-mr-2 edit-icon-background" onClick={() => editfunc(memberDetails[selectedMemberID])}>Edit Data</button>
                    </>
                  ) : (
                    <>

                    </>
                  )}

                </>
              ) : (
                <p>No data available for this member.</p>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <div className="card">
      <div>
        {role === "6533e900de21620f67bef3b4" ? (
          <>
            <table border="1">
           <thead>
             <tr>
               <th>Member ID</th>
               <th>Email</th>
               <th>Status</th>
               <th>Action</th>
             </tr>
           </thead>
           <tbody>
             {cusData && cusData.map((item, index) => {
               // Save the 'credit' value from a specific index (e.g., index 0)
               if (item._id === id) {
                return (
                  <tr key={index}>
                    <td>{item.MemberId}</td>
                    <td>{item.email}</td>
                    <td>{item.ApplicationStatus == true ? "Complete" : "In-complete"}</td>
                    <td><button className="pi pi-eye ml-2 p-ml-2 view-icon-background" onClick={() => viewMemberDetails(item._id)}>View</button> </td>
                  </tr>
                );
               }
              
             })}
           </tbody>
           
         </table>
          </>
        ) : (
          <>
             <DataTable
          value={cusData}
          lazy
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          rows={10000}
        >
          {columns.map((col, i) => (
            <Column key={col.field} field={col.field} header={col.header} body={col.body} />
          ))}
          <Column header="Action" body={icon} />
        </DataTable>
          </>
        )}
      </div>
       
      </div>
    </>
  );
}

export default CompanyData;
