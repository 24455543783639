import React, { useState, useEffect, useRef } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import '../../Client-management/printFile.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const VoucherPrint = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ledger, setLedger] = useState([]);
    const [plot, setPlot] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const response = await Axios.get(`https://backend.pgechs.online/voucher/all`);
                if (response.status === 200) {
                    const { data } = response;
                    if (data.data != null) {
                        // Use the MemberID as the key to store member details
                        console.log(data);
                        setLedger(data.data);
                    } else {
                        console.log(data.msg);
                    }
                } else {
                    console.log("No data found from API");
                }
            } catch (error) {
                console.error("Error fetching data from API:", error);
            }
        };
        const fetchAllEmployeeSalaries = async (ledger) => {
            const allSalaries = {};
            for (const item of ledger) {
                try {
                    const response = await Axios.get(`https://backend.pgechs.online/employeeSalary/all/${item._id}`);
                    if (response.status === 200 && response.data.data) {
                        allSalaries[item._id] = response.data.data;
                    }
                } catch (error) {
                    console.error("Error fetching data from API:", error);
                }
            }
            return allSalaries;
        };
        const fetchData = async () => {
            const salaries = await fetchAllEmployeeSalaries(ledger);
            setEmployeeSalaries(salaries);
            setIsLoading(false);
        };

        // Call the function to fetch member data
        fetchMemberData();
        fetchData();
    }, []);
    const [employeeSalaries, setEmployeeSalaries] = useState({});

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
    };
    const handleDownloadPDF = () => {
        window.print();
    };
    let srNo = 0;
    let sumOfDebit = 0;
    let month1 = 0;
    let month2 = 0;
    let month3 = 0;
    let month4 = 0;
    let month5 = 0;
    let month6 = 0;
    let month7 = 0;
    let month8 = 0;
    let month9 = 0;
    let month10 = 0;
    let month11 = 0;
    let month12 = 0;
    let monthTotal1 = 0;
    let monthTotal2 = 0;
    let monthTotal3 = 0;
    let monthTotal4 = 0;
    let monthTotal5 = 0;
    let monthTotal6 = 0;
    let monthTotal7 = 0;
    let monthTotal8 = 0;
    let monthTotal9 = 0;
    let monthTotal10 = 0;
    let monthTotal11 = 0;
    let monthTotal12 = 0;
    let totalSalary = 0;
    let grandTotalSalary = 0;
    let flag = true;

    let remainingBalance = 0;
    function DateComponent({ dateString }) {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date
        const formattedDate = `${year}-${month}-${day}`;
        return <div>{formattedDate}</div>;
    }
    const MonthComponent = (dateString) => {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date   
        return month;
    }
    const dateComponent = (dateString) => {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        // Format the date   
        return formattedDate;
    }
    const [selectedDate, setSelectedDate] = useState('');

    // OnClick function for the button
    const handleButtonClick = async() => {
        // Your logic on button click goes here
        try {
            const response = await Axios.get(`https://backend.pgechs.online/voucher/all`);
            if (response.status === 200) {
                const { data } = response;
                if (data.data != null) {
                    // Use the MemberID as the key to store member details
                    console.log(data);
                    setLedger(data.data);
                } else {
                    console.log(data.msg);
                }
            } else {
                console.log("No data found from API");
            }
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
        
        const results = ledger.filter(item =>
            dateComponent(item.date).toLowerCase().includes(selectedDate.toLowerCase())
          );
          // Update the searchResults state with the filtered data
          setLedger(results);
          console.log('Data is:', ledger);
    };
    let totalDebit = 0;
    let totalCredit = 0;
    return (
        <>
            {isLoading === true ? (<h1>Please wait data is loading</h1>) : (
                <div id="divToPrint" className="card">
                    <br></br>
                    <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>The Pakistan Government Employees Co-operative Housing Society Ltd.</h2>

                    <h4 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Opening Balance</h4>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Date Field */}
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />

                        {/* Button */}
                        <button onClick={handleButtonClick}>Get List</button>
                    </div>
                    <table border="2">
                        <thead>
                            <tr>
                                <th>Voucher No.</th>
                                <th>Reason</th>
                                <th>Date</th>
                                <th>Amount</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {ledger && ledger.map((item, index) => {
                                // Save the 'credit' value from a specific index (e.g., index 0)
                                srNo++;
                                totalCredit = totalCredit + item.amount;
                                totalDebit = totalDebit + item.amount;
                                return (
                                    <tr key={index}>
                                        <td>{item.vNo}</td>
                                        <td>{item.name}</td>
                                        <td><DateComponent dateString={item.date} /></td>
                                        <td>{item.amount}</td>
                                       
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td>Total:</td>
                                <td></td>
                                <td>{totalCredit}</td>
                                
                            </tr>

                        </tfoot>
                    </table>
                    <br></br>
                    <button onClick={handleDownloadPDF}>Download PDF</button>
                </div>
            )
            };
        </>
    );
};

export default VoucherPrint;