import React, { useState, useEffect } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';

export default function InventoryForm({ geteditdata, editable, getAllCustomers, onHide, memberID }) {

    //console.log("editable", editable)
    const [getErrorMessage, setErrorMessage] = useState(null);
    const validationSchema = Yup.object().shape({

        chequeORdraft: Yup.string().required("Cheque/Draft are required."),
        Slip: Yup.string().required("Slip Number is required."),
        debit: Yup.number().required("Debit is required."),
        credit: Yup.number().required("Credit is required."),

        date: Yup.date().required("Date is required."),

    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>;
    };

    // console.log("geteditdata", geteditdata)

    useEffect(() => {
        console.log(geteditdata)
        if (editable === true) {
            setSelectedOption(geteditdata?.particulars)
            console.log(geteditdata)
            formik.setFieldValue("chequeORdraft", geteditdata?.chequeORdraft)
            formik.setFieldValue("Slip", geteditdata?.Slip)
            formik.setFieldValue("debit", geteditdata?.debit)
            formik.setFieldValue("date", geteditdata?.date.substring(0, geteditdata?.date.indexOf('T')))
            formik.setFieldValue("credit", geteditdata?.credit)
            formik.setFieldValue("extraCharges", geteditdata?.extraCharges)
        }
    }, [editable]);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            particulars: "",
            chequeORdraft: "",
            Slip: "",
            debit: "",
            credit: "",
            extraCharges: "",
            date: "",
            email: ""
        },
        validate: (data) => {
        },
        onSubmit: async (values) => {
            console.log(values);
            if (editable === true) {
                const obj = {
                    particulars: selectedOption,
                    chequeORdraft: formik.values.chequeORdraft,
                    Slip: formik.values.Slip,
                    debit: formik.values.debit,
                    credit: formik.values.credit,
                    extraCharges: formik.values.extraCharges,
                    date: formik.values.date,
                    email: geteditdata?.MemberID.member_id.email
                }
                try {
                    const res = await Axios.put(`https://backend.pgechs.online/ledger/updateStatus/${geteditdata?._id}`, obj)
                    console.log("putresponse", res);
                    await getAllCustomers();
                    onHide()
                    console.log("Response success", res.data)
                } catch (error) {
                    console.log("Error in Updation" + error)
                }
                onHide();
                getAllCustomers();
            }
            else {
                const obj = {
                    particulars: selectedOption,
                    chequeORdraft: formik.values.chequeORdraft,
                    Slip: formik.values.Slip,
                    debit: formik.values.debit,
                    credit: formik.values.credit,
                    extraCharges: formik.values.extraCharges,
                    date: formik.values.date,
                    email: geteditdata?.member_id.email
                }
                console.log("obj: ",obj);
                try {
                    const res = await Axios.post(`https://backend.pgechs.online/ledger/add/${memberID}`, obj, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        validateStatus: function (status) {
                            return status === 200 || status === 409; // Axios will throw an error for other status codes
                        },
                    });
                    if (res.status === 409) {
                        const { data } = res;
                        console.log('Error: ' + data.message)
                        setErrorMessage(data.message);
                    } else if (res.status === 200) {
                        await getAllCustomers();
                        onHide();
                        // You can redirect or perform other actions on successful registration
                    } else {
                        console.log("Unexpected response status:", res.status);
                    }
                } catch (error) {
                    console.log("Catched error: " + error)
                }


            }

        },
    });
    useEffect(() => {

    }, []); // Empty dependency array ensures this effect runs only once

    const [selectedOption, setSelectedOption] = useState('');

    // Options for the dropdown
    const options = ['Cost of Land', 'Development Charges', 'Additional Development Charges', 'Sui-Gas Charges', 'Electricity Charges', 'Possession Fee', 'Utility Funds', 'Water Supply Charges'];

    // Event handler for option selection
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    //  console.log("formik.values.Date", formik.values.Date)
    return (
        <div className='card'>
            <form onSubmit={formik.handleSubmit} >
                <div className="p-fluid p-formgrid p-grid grid row">
                    <div className="p-field col-xs-12 col-sm-6 col-md-6  col-lg-6">
                        <label
                            className={classNames({ "p-error": isFormFieldValid("particulars") })}>Particulars
                        </label>
                        <select id="dropdown" style={{ paddingLeft: '50px', paddingRight: '50px', fontSize: '16px' }} value={selectedOption} onChange={handleOptionChange}>
                            <option value="">Select Particulars...</option>
                            {options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        {getFormErrorMessage("particulars")}
                    </div>

                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label
                            className={classNames({ "p-error": isFormFieldValid("chequeORdraft") })}>
                            Cheque/Draft </label>
                        <InputText
                            id="chequeORdraft"
                            name="chequeORdraft"
                            value={formik.values.chequeORdraft}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("chequeORdraft") }, "")}

                        />
                        {getFormErrorMessage("chequeORdraft")}
                    </div>

                </div>
                <div className="p-fluid p-formgrid p-grid grid row">
                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="chemical_formulation"
                            className={classNames({ "p-error": isFormFieldValid("Slip") })}>Slip</label>
                        <InputText
                            id="Slip"
                            type="text"
                            name="Slip"
                            value={formik.values.Slip}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("Slip") }, "")}

                        />
                        {getFormErrorMessage("Slip")}

                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label
                            className={classNames({ "p-error": isFormFieldValid("debit") })}>Debit: </label>
                        <InputText
                            id="debit"
                            type="text"
                            name="debit"
                            value={formik.values.debit}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("debit") }, "")}

                        />
                        {getFormErrorMessage("debit")}
                    </div>

                </div>
                <div className="p-fluid p-formgrid p-grid grid row">
                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label
                            className={classNames({ "p-error": isFormFieldValid("credit") })}>Credit</label>
                        <InputText
                            id="credit"
                            type="text"
                            name="credit"
                            value={formik.values.credit}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("credit") }, "")}

                        />
                        {getFormErrorMessage("credit")}

                    </div>

                </div>
                <div className="p-fluid p-formgrid p-grid grid row">
                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label
                            className={classNames({ "p-error": isFormFieldValid("extraCharges") })}>Extra Charges </label>
                        <InputText
                            id="extraCharges"
                            type="text"
                            name="extraCharges"
                            value={formik.values.extraCharges}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("extraCharges") }, "")}
                        />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <label
                            className={classNames({ "p-error": isFormFieldValid("date") })}> Date</label>
                        <InputText
                            id="date"
                            type="date"
                            name="date"
                            value={formik.values.date}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("date") }, "")}

                        />
                        {getFormErrorMessage("date")}

                    </div>


                </div>
                <div className="p-fluid p-formgrid p-grid grid row">


                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6 p-6">
                        <Tag value={getErrorMessage} severity="danger"></Tag>
                    </div>
                </div>

                <div className='flex cus-buton'>
                    <Button label="SUBMIT" className="p-button-rounded p-button-success" type='submit'
                        autoFocus />
                </div>
            </form>
        </div>
    )
}