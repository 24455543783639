import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import IncomeStatementForm from "./IncomeStatementForm";
import '../../Company-Management/search.css';
import {Link} from "react-router-dom";
function IncomeStatement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBy, setSearchBy] = useState('memberId'); // Default search criteria
  const [cusData, setCusData] = useState([]);
  const [displayPosition, setDisplayPosition] = useState(false);
  const [position, setPosition] = useState("center");

  const [MemberdisplayPosition, setMemberDisplayPosition] = useState(false);
  const [Memberposition, setMemberPosition] = useState("center");
  const [selectedMemberID, setMemberID] = useState();

  const [edittable, setedittable] = useState(false);
  const [editdata, seteditdata] = useState();
  const [getId, setGetId] = useState();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState('');

  // Create an object to store member details with MemberID as keys
  const [memberDetails, setMemberDetails] = useState([]);
  const [memberDetail, setMemberDetail] = useState([]);
  useEffect(() => {
    getAllCustomers();
  }, []);
  const handleSearch = async () => {

    if (searchBy === "email") {
      const results = cusData.filter(item =>
        item.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Update the searchResults state with the filtered data
      setCusData(results);
    }
    if (searchBy === "memberId") {

      const results = cusData.filter(item =>
        item.MemberId.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Update the searchResults state with the filtered data
      setCusData(results);
    }
    if (searchBy === "") {
      const response = await Axios.get("https://backend.pgechs.online/admin/getAllRegisterdMembers");
      if (response.status === 200) {
        const { data } = response;
        setCusData(data.data);
      } else {
        console.log("No data found from API");
      }
    }

    console.log(`Searching by ${searchBy}: ${searchTerm}`);
  };
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
 
    if (position) {
      setPosition(position);
    }
  };

  const CustomIcon = () => (
    // This is a hypothetical icon component, replace it with your actual icon implementation
    <span style={{ marginRight: '8px' }}>🚀</span>
  );
  
  const IconButton = ({ onClick, text }) => (
    <button onClick={onClick} style={{ display: 'flex', alignItems: 'center' , backgroundColor: 'black'}}>
      <CustomIcon />
      <span>{text}</span>
    </button>
  );
  const [showPopup, setShowPopup] = useState(false);

  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");
  const icon = (rowData) => {
    
    return (
      <div>
        {role === "6533e8c7de21620f67bef3aa" ? (
          <>
            <button className="pi pi-pencil ml-2 p-mr-2 edit-icon-background" onClick={() => editfunc(rowData)}>Edit</button>
            <button className="pi pi-trash ml-2 p-ml-2 delete-icon-background" onClick={() => deleteUserData(rowData)}>Delete</button>
          </>
        ) : (
          <>
            <button className="pi pi-pencil ml-2 p-mr-2 edit-icon-background" onClick={() => editfunc(rowData)}>Edit</button>
            <button className="pi pi-trash ml-2 p-ml-2 delete-icon-background" onClick={() => deleteUserData(rowData)}>Delete</button>
          </>
        )}
      </div>
    );
  };

  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);

    setMemberDisplayPosition(false);
    setMemberPosition(false);
  };

  const getAllCustomers = async () => {
    const response = await Axios.get("https://backend.pgechs.online/incomeStatement/all");
    if (response.status === 200) {
      const { data } = response;
      setCusData(data.data);
    } else {
      console.log("No data found from API");
    }
  };
  const [isPopupOpen, setPopupOpen] = useState(false);

  

  const closePopup = () => {
    setPopupOpen(false);
  };
  const confirmDelete = async () => {

    
      const obj = {
        email: email
      }
      const response = await Axios.delete(`https://backend.pgechs.online/incomeStatement/delete/${getMemberIDforDeletion}`, obj);
      if (response.status === 200) {
        getAllCustomers();
      }
    
    setDeleteConfirmationVisible(false);
  }
  const [getMemberIDforDeletion, setMemberIDforDeletion] = useState(null);
  const [getApplicationStatus, setApplicationStatus] = useState(null);
  const [email, setEmail] = useState("");
  
  const deleteUserData = (rowData) => {
    setMemberIDforDeletion(rowData._id);
    
    setDeleteConfirmationVisible(true);
  };

  const [membersData, setMembersData] = useState({});

  const addMemberDetails = () => {
    
    onClick("displayPosition", "right");
  };

  const editfunc = (rowData) => {
    setDisplayPosition(true);
    setPosition("right");
    setedittable(true);
    seteditdata(rowData);
  };

  const openPopup = (rowData) => {
    console.log(rowData);
    seteditdata(rowData);
    setPosition("right");
    
    setShowPopup(true);
    setDisplayPosition(true);
  };
  const viewMemberDetails = async (rowData) => {
    setMemberID(rowData);
  };




  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button label="Cancel" className="p-button-secondary" onClick={() => setDeleteConfirmationVisible(false)} />
      <Button label="Delete" className="p-button-danger" onClick={() => confirmDelete()} />
    </div>
  );

  const columns = [
    { field: 'electionFee', header: 'Election Fee' },
    { field: 'misIncome', header: 'Misc. Income'},
    { field: 'msFee', header: 'MS Fee'},
    { field: 'shareMoney', header: 'Share Money' },
    { field: 'transferFee', header: 'Transfer Fee' },
    { field: 'pFee', header: 'P Fee'},
    { field: 'masjidFund', header: 'Masjid Fund'},
    { field: 'ADC', header: 'Additional Development Charges' },
    { field: 'extraADC', header: 'Extra ADC' },
    { field: 'electricityCharges', header: 'Electricity Charges' },
    { field: 'suiGasCharges', header: 'Sui-Gas Charges' },
    { field: 'bankProfit', header: 'Bank Profit'},
    { field: 'cornerFee', header: 'Corner Fee'},
    { field: 'date', header: 'Date' },
    
  ];
  const [selectedOption, setSelectedOption] = useState('');

  // Options for the dropdown
  const options = ['Repair', 'Photocopy', 'Salary', 'Conveyance', 'Gen.Petrol', 'Advertisement', 'Postage', 'Electricity Bill', 'Development Charges', 'Sui-Gas Bill', 'Land Expenses', 'PTCL Bill', 'Stationary/Printing', 
  'Miscelleneous', 'Legal Fee/Legal Expenses/Tax', 'Entertainment', 'Crokery', 'Office Rent', 'Office Security', 'Refund', 'Stock', 'Bank Charges', 'With Holding Tax', 'News Pape Bill', 'TA&DA Expenses', 'Depreciation', 'Possession of Plot', 'Water Tanker Expenses', 
  'Planation Expenses', 'WAPDA Expenses', 'Cable Bill', 'Audit Fee', 'Election Expenses', 'Income Tax Expenses', 'Honorarioum', 'Maps Consultant Fee', 'AGM Expenses', 'Impress Money/CDR'];

  // Event handler for option selection
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSearch1 = async () => {
      
    const results = cusData.filter(item =>
      item.head.toLowerCase().includes(selectedOption.toLowerCase())
    );
    // Update the searchResults state with the filtered data
    setCusData(results);
};
function DateComponent({ dateString }) {
  // Convert the MongoDB date string to a Date object
  const date = new Date(dateString);

  // Extract the date components
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(date.getUTCDate()).padStart(2, '0');

  // Format the date
  const formattedDate = `${year}-${month}-${day}`;

  return <div>{formattedDate}</div>;
}
  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>
      <>
      
      </>
      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">Income Statement Management</h3>
            </div>

            <div className="search-container">
           
            <input
                type="text"
                className="search-input"
                placeholder="Enter search term"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button onClick={handleSearch1} className="search-button">
                Search
              </button>
              <button onClick={() => addMemberDetails()} className="search-button1">
                Add Income Statement
              </button>
              <button className="search-button2">
              <Link to={{ pathname: '/incomeStatementPrint' }}>Get Print</Link>
              </button>
            </div>
          </div>
          
          
          <Dialog
            header={edittable ? "Edit Income Statement Detail * " : "Add Income Statement Detail * "}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <IncomeStatementForm
              getEditData={editdata}
              memberDetails={memberDetails}
              getId={getId}
              editable={edittable}
              onHide={onHide}
              memberID={membersData}
              getAllCustomers={getAllCustomers}
              showPopup={showPopup}
            />
          </Dialog>
          
        </div>
      </div>
      {
        cusData.length != 0 ? (
          <>
        <div className="card">
      <div>
        {role === "6533e900de21620f67bef3b4" ? (
         <DataTable
         value={cusData}
         lazy
         responsiveLayout="scroll"
         dataKey="id"
         paginator
         rows={10000}
       >
         {columns.map((col, i) => (
           <Column key={col.field} field={col.field} header={col.header} body={col.body} />
         ))}
         <Column header="Action" body={icon} />
       </DataTable>
        ) : (
          <>
             <DataTable
          value={cusData}
          lazy
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          rows={10000}
        >
          {columns.map((col, i) => (
            <Column key={col.field} field={col.field} header={col.header} body={col.body} />
          ))}
          <Column header="Action" body={icon} />
        </DataTable>
          </>
        )}
      </div>
       
      </div>
        </>
        ):
        (<>
         
        
        </>
        )
      }
      
    </>
  );
}

export default IncomeStatement;
