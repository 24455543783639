import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import classNames from "classnames";
export default function IncomeStatementForm({ memberDetails, getEditData, editable, getId, getAllCustomers, onHide, memberID, showPopup }) {

  const [errorMEssage, setErrorMessage] = useState(null);
  const [successMEssage, setSuccessMessage] = useState(null);
  const validationSchema = Yup.object().shape({
    date: Yup.string().required('This field is required.'),
    electionFee: Yup.string().required('This field is required.'),
    misIncome: Yup.string().required('This field is required.'),
    shareMoney: Yup.string().required('This field is required.'),
    msFee: Yup.string().required('This field is required.'),
    pFee: Yup.string().required('This field is required.'),
    transferFee: Yup.string().required('This field is required.'),
    suiGasCharges: Yup.string().required('This field is required.'),
    bankProfit: Yup.string().required('This field is required.'),
    cornerFee: Yup.string().required('This field is required.'),
    masjidFund: Yup.string().required('This field is required.'),
    ADC: Yup.string().required('This field is required.'),
    extraADC: Yup.string().required('This field is required.'),
    electricityCharges: Yup.string().required('This field is required.'),
  });
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  function DateComponent({ dateString }) {
    // Convert the MongoDB date string to a Date object
    const date = new Date(dateString);
  
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
  
    return <div>{formattedDate}</div>;
  }
  useEffect(() => {
    if (editable === true) {
      setSelectedOption(getEditData?.head)
      formik.setFieldValue("date", getEditData?.date.substring(0, getEditData?.date.indexOf('T')));
      formik.setFieldValue("electionFee", getEditData?.electionFee);
      formik.setFieldValue("misIncome", getEditData?.misIncome);
      formik.setFieldValue("msFee", getEditData?.msFee);
      formik.setFieldValue("shareMoney", getEditData?.shareMoney);
      formik.setFieldValue("transferFee", getEditData?.transferFee);
      formik.setFieldValue("pFee", getEditData?.pFee);
      formik.setFieldValue("masjidFund", getEditData?.masjidFund);
      formik.setFieldValue("ADC", getEditData?.ADC);
      formik.setFieldValue("extraADC", getEditData?.extraADC);
      formik.setFieldValue("electricityCharges", getEditData?.electricityCharges);
      formik.setFieldValue("suiGasCharges", getEditData?.suiGasCharges);
      formik.setFieldValue("bankProfit", getEditData?.bankProfit);
      formik.setFieldValue("cornerFee", getEditData?.cornerFee);
      
    }

  }, [editable]);
  const formik = useFormik({
    validationSchema,
    initialValues: {
      electionFee: '',
      misIncome: '',
      msFee: '',
      shareMoney:'',
      transferFee: '',
      pFee: '',
      masjidFund: '',
      ADC: '',
      extraADC: '',
      electricityCharges: '',
      suiGasCharges: '',
      bankProfit: '',
      cornerFee: '',
      date: '',
    },
    onSubmit: async (values) => {
      console.log(selectedOption);
      if (editable === true) {
        const obj = {
          date: formik.values.date,
          electionFee: formik.values.electionFee,
          misIncome: formik.values.misIncome,
          shareMoney: formik.values.shareMoney,
          msFee: formik.values.msFee,
          transferFee: formik.values.transferFee,
          pFee: formik.values.pFee,
          masjidFund: formik.values.masjidFund,
          ADC: formik.values.ADC,
          extraADC: formik.values.extraADC,
          electricityCharges: formik.values.electricityCharges,
          suiGasCharges: formik.values.suiGasCharges,
          bankProfit: formik.values.bankProfit,
          cornerFee: formik.values.cornerFee,
        }
        console.log("obj", obj);
        try {
          const res = await Axios.put(`https://backend.pgechs.online/incomeStatement/update/${getEditData?._id}`, obj)
          console.log("putresponse", res)

          await getAllCustomers();
          onHide()

          console.log("Response success", res.data)
        } catch (error) {
          console.log("Error in Updation" + error)
        }
        getAllCustomers();
      }
      else {
        try {
          const obj = {
            date: formik.values.date,
          electionFee: formik.values.electionFee,
          misIncome: formik.values.misIncome,
          shareMoney: formik.values.shareMoney,
          msFee: formik.values.msFee,
          transferFee: formik.values.transferFee,
          pFee: formik.values.pFee,
          masjidFund: formik.values.masjidFund,
          ADC: formik.values.ADC,
          extraADC: formik.values.extraADC,
          electricityCharges: formik.values.electricityCharges,
          suiGasCharges: formik.values.suiGasCharges,
          bankProfit: formik.values.bankProfit,
          cornerFee: formik.values.cornerFee,
          }
          console.log("object has: ", obj);
          // Make a POST request to the backend
          const response = await Axios.post(`https://backend.pgechs.online/incomeStatement/add`, obj, {
            headers: {
              'Content-Type': 'application/json',
            },
            validateStatus: function (status) {

              if (status === 200) {
                getAllCustomers();
                onHide();
              }
              return status === 200 || status === 409; // Axios will throw an error for other status codes
            },

          });

          if (response.status === 409) {
            const { data } = response;
            console.log('Error: ' + data.message)
            setErrorMessage(data.message)
          } else if (response.status === 200) {
            const { data } = response;
            console.log('Error: ' + data.message)
            setSuccessMessage(data.message)
            onHide();
            // You can redirect or perform other actions on successful registration
          } else {
            console.log("Unexpected response status:", response.status);
          }

        } catch (error) {
          // Handle errors (e.g., show an error message)
          console.error('API Error:', error);
        }
      }

    },
  });
  const [selectedOption, setSelectedOption] = useState('');

  // Options for the dropdown
  const options = ['Repair', 'Photocopy', 'Salary', 'Conveyance', 'Gen.Petrol', 'Advertisement', 'Postage', 'Electricity Bill', 'Development Charges', 'Sui-Gas Bill', 'Land Expenses', 'PTCL Bill', 'Stationary/Printing', 
  'Miscelleneous', 'Legal Fee/Legal Expenses/Tax', 'Entertainment', 'Crokery', 'Office Rent', 'Office Security', 'Refund', 'Stock', 'Bank Charges', 'With Holding Tax', 'News Pape Bill', 'TA&DA Expenses', 'Depreciation', 'Possession of Plot', 'Water Tanker Expenses', 
  'Planation Expenses', 'WAPDA Expenses', 'Cable Bill', 'Audit Fee', 'Election Expenses', 'Income Tax Expenses', 'Honorarioum', 'Maps Consultant Fee', 'AGM Expenses', 'Impress Money/CDR'];

  // Event handler for option selection
  const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
  };
  const onSubmit = () => {
    console.log("Clicked")
};
  return (
    <div>
      <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid p-formgrid p-grid grid row">
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Election Fee</label>
              <InputText
                id="electionFee"
                name="electionFee"
                type='number'
                value={formik.values.electionFee}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Misc. Income</label>
              <InputText
                id="misIncome"
                name="misIncome"
                type='number'
                value={formik.values.misIncome}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>MS Fee</label>
              <InputText
                id="msFee"
                name="msFee"
                type='number'
                value={formik.values.msFee}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Share Money</label>
              <InputText
                id="shareMoney"
                name="shareMoney"
                type='number'
                value={formik.values.shareMoney}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Transfer Fee</label>
              <InputText
                id="transferFee"
                name="transferFee"
                type='number'
                value={formik.values.transferFee}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>P Fee</label>
              <InputText
                id="pFee"
                name="pFee"
                type='number'
                value={formik.values.pFee}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Masjid Fund</label>
              <InputText
                id="masjidFund"
                name="masjidFund"
                type='number'
                value={formik.values.masjidFund}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>ADC</label>
              <InputText
                id="ADC"
                name="ADC"
                type='number'
                value={formik.values.ADC}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Extra ADC</label>
              <InputText
                id="extraADC"
                name="extraADC"
                type='number'
                value={formik.values.extraADC}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Electricity Charges</label>
              <InputText
                id="electricityCharges"
                name="electricityCharges"
                type='number'
                value={formik.values.electricityCharges}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Sui-Gas Charges</label>
              <InputText
                id="suiGasCharges"
                name="suiGasCharges"
                type='number'
                value={formik.values.suiGasCharges}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Bank Profit</label>
              <InputText
                id="bankProfit"
                name="bankProfit"
                type='number'
                value={formik.values.bankProfit}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Corner Fee</label>
              <InputText
                id="cornerFee"
                name="cornerFee"
                type='number'
                value={formik.values.cornerFee}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label> Date</label>
              <InputText
                id="date"
                type="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
              />
            </div>
            

            <div className="flex cus-buton">
              <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" 
           
              />
            </div>
          </div>
        </form>
      </div>
    </div>

  );
}
