import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

export default function MemberForm({ memberDetails, getEditData, editable, getId, getAllCustomers, onHide, memberID, showPopup }) {
  const [fileData, setFileData] = useState({});
  const [errorMEssage, setErrorMessage] = useState(null);
  const [successMEssage, setSuccessMessage] = useState(null);
  const [memberDetail, setMemberDetail] = useState([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.'),
    address: Yup.string().required('This field is required.'),
    phoneNumber: Yup.string().required('This field is required.'),
    cnic: Yup.string().required('This field is required.'),
  });

  useEffect(() => {
    if (editable === true) {
      formik.setFieldValue("name", getEditData?.name);
      formik.setFieldValue("address", getEditData?.address);
      formik.setFieldValue("phoneNumber", getEditData?.phoneNumber);
      formik.setFieldValue("cnic", getEditData?.cnic);
    }

  }, [editable]);
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      address: '',
      phoneNumber: '',
      cnic: '',
      email: ''
    },
    onSubmit: async (values) => {
      if (editable === true) {
        const obj = {
          name: formik.values.name,
          address: formik.values.address,
          phoneNumber: formik.values.phoneNumber,
          cnic: formik.values.cnic,
          email: getEditData?.member_id.email
        }
        console.log("obj", obj);
        try {
          const res = await Axios.put(`https://backend.pgechs.online/members/update/${getEditData?._id}`, obj)
          console.log("putresponse", res)

          await getAllCustomers();
          onHide()

          console.log("Response success", res.data)
        } catch (error) {
          console.log("Error in Updation" + error)
        }
        getAllCustomers();
      }
      else {
        try {
          const formData = new FormData();
          formData.append('name', values.name);
          formData.append('address', values.address);
          formData.append('phoneNumber', values.phoneNumber);
          formData.append('cnic', values.cnic);
          formData.append('email', getEditData?.email);
          console.log(getEditData?.email);
          // Append file data to formData
          for (const field in fileData) {
            formData.append(field, fileData[field]);
          }
          // Make a POST request to the backend
          const response = await Axios.post(`https://backend.pgechs.online/members/addDetails/${memberID}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            validateStatus: function (status) {

              if (status === 200) {
                getAllCustomers();
                onHide();
              }
              return status === 200 || status === 409; // Axios will throw an error for other status codes
            },

          });

          if (response.status === 409) {
            const { data } = response;
            console.log('Error: ' + data.message)
            setErrorMessage(data.message)
          } else if (response.status === 200) {
            const { data } = response;
            console.log('Error: ' + data.message)
            setSuccessMessage(data.message)
            onHide();
            // You can redirect or perform other actions on successful registration
          } else {
            console.log("Unexpected response status:", response.status);
          }

        } catch (error) {
          // Handle errors (e.g., show an error message)
          console.error('API Error:', error);
        }
      }

    },
  });

  const [email, setEmail] = useState('');
  

  // Function to handle form submission
  const handleSubmit = async(event) => {
    event.preventDefault();
    console.log("Sending data into backend");
    console.log("email ", email)
    console.log("id ", getEditData?._id)
    const obj = {
      email: email
    }

    console.log("obj", obj)

    try {
      const res = await Axios.put(`https://backend.pgechs.online/members/transfer/${getEditData?._id}`, obj)
      console.log("putresponse", res)

      await getAllCustomers();
      onHide()

      console.log("Response success", res.data)
    } catch (error) {
      console.log("Error in Updation" + error)
    }
    getAllCustomers();
    // You can send the email value to a server or process it further here
  };
  const handleFileChange = (event) => {
    const fieldName = event.target.name;
    const file = event.target.files[0];

    setFileData((prevFileData) => ({
      ...prevFileData,
      [fieldName]: file,
    }));
  };

  return (
    <div>
      {editable ? (
        <div className="card">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid grid row">
              <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label>Name</label>
                <InputText
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
              </div>
              <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label>Address</label>
                <InputText
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
              </div>
              <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label>Phone Number</label>
                <InputText
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
              </div>
              <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label>CNIC</label>
                <InputText
                  id="cnic"
                  name="cnic"
                  value={formik.values.cnic}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
              </div>

              <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6 p-6">
                <Tag value={errorMEssage} severity="danger"></Tag>
              </div>
              <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6 p-6">
                <Tag value={errorMEssage} severity="success"></Tag>
              </div>


              <div className="flex cus-buton">
                <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <>
          {showPopup ? (
            <div className="card">
              <div>
                <h2>Tranfer Email</h2>
                <form onSubmit={handleSubmit}>
                <div className="p-fluid p-formgrid p-grid grid row">
                <label>Email</label>
                      <InputText
                      type="email" 
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)} 
                      required
                      />
                    <div className="flex cus-buton">
                      <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <>
              <div className="card">
                <form onSubmit={formik.handleSubmit}>
                  <div className="p-fluid p-formgrid p-grid grid row">
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Name</label>
                      <InputText
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Address</label>
                      <InputText
                        id="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Phone Number</label>
                      <InputText
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>CNIC</label>
                      <InputText
                        id="cnic"
                        name="cnic"
                        value={formik.values.cnic}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.name && formik.errors.name && <small className="p-error mt-1 ml-2">{formik.errors.name}</small>}
                    </div>
                    {/* Add other form fields as needed */}
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Allotment Certificate</label>
                      <input type="file" name="allotmentCertificate" onChange={handleFileChange} />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Membership Transfer</label>
                      <input type="file" name="membershipTransfer" onChange={handleFileChange} />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Application Form</label>
                      <input type="file" name="applicationForm" onChange={handleFileChange} />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Undertaking</label>
                      <input type="file" name="underTaking" onChange={handleFileChange} />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Affidavit</label>
                      <input type="file" name="affidavit" onChange={handleFileChange} />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Transfer image</label>
                      <input type="file" name="transferImage" onChange={handleFileChange} />
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Upload Merged PDF Document</label>
                      <input type="file" name="mergedPDF" onChange={handleFileChange} />
                    </div>
                    {/* Repeat the above block for other file uploads */}


                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6 p-6">
                      <Tag value={errorMEssage} severity="danger"></Tag>
                    </div>
                    <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6 p-6">
                      <Tag value={errorMEssage} severity="success"></Tag>
                    </div>


                    <div className="flex cus-buton">
                      <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" />
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </div>

  );
}
