import React, { useState } from 'react';
import './BalanceSheet.css'; // You can create a separate CSS file for styling

const BalanceSheet = () => {
  const [assets, setAssets] = useState(0);
  const [shareCapital, setShareCapital] = useState(0);
  const [deficit, setDeficit] = useState(0);
  const [costLand, setCostLand] = useState(0);
  const [mosqueFund, setMosqueFund] = useState(0);
  const [suiGasCharges, setSuiGasCharges] = useState(0);
  const [land, setLand] = useState(0);
  const [developmentExpenditure, setDevelopmmentExpenditure] = useState(0);
  const [externalElectrification, setExternalElectrification] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [cash, setCash] = useState(0);
  const [suspenseAccount, setSuspenseAccount] = useState(0);
  const [assets2, setAssets2] = useState(0);
  const [shareCapital2, setShareCapital2] = useState(0);
  const [deficit2, setDeficit2] = useState(0);
  const [costLand2, setCostLand2] = useState(0);
  const [mosqueFund2, setMosqueFund2] = useState(0);
  const [suiGasCharges2, setSuiGasCharges2] = useState(0);
  const [land2, setLand2] = useState(0);
  const [developmentExpenditure2, setDevelopmmentExpenditure2] = useState(0);
  const [externalElectrification2, setExternalElectrification2] = useState(0);
  const [advance2, setAdvance2] = useState(0);
  const [cash2, setCash2] = useState(0);
  const [suspenseAccount2, setSuspenseAccount2] = useState(0);
  let [totalShareCapital, setTotalShareCapital] = useState(0);
  let [totalShareCapital2, setTotalShareCapital2] = useState(0);
  let [totalMemberDeposit, setTotalMemberDeposit] = useState(0);
  let [totalMemberDeposit2, setTotalMemberDeposit2] = useState(0);
  let [grandTotal, setGrandTotal] = useState(0);
  let [grandTotal2, setGrandTotal2] = useState(0);
  let [grandTotal3, setGrandTotal3] = useState(0);
  let [grandTotal4, setGrandTotal4] = useState(0);
  let [totalLand, setTotalLand] = useState(0);
  let [totalLand2, setTotalLand2] = useState(0);
  const handleDownloadPDF = () => {
    totalShareCapital = setTotalShareCapital(parseInt(deficit, 10)-parseInt(shareCapital, 10));
    totalShareCapital2 = setTotalShareCapital2(parseInt(deficit2, 10) - parseInt(shareCapital2, 10));
    totalMemberDeposit = setTotalMemberDeposit(parseInt(costLand, 10)+parseInt(mosqueFund, 10) + parseInt(suiGasCharges, 10));
    totalMemberDeposit2 = setTotalMemberDeposit2(parseInt(costLand2, 10)+parseInt(mosqueFund2, 10) + parseInt(suiGasCharges2, 10));
    grandTotal = setGrandTotal((parseInt(costLand, 10)+parseInt(mosqueFund, 10) + parseInt(suiGasCharges, 10)) - (parseInt(deficit, 10) - parseInt(shareCapital, 10)));
    grandTotal2 = setGrandTotal2((parseInt(costLand2, 10)+parseInt(mosqueFund2, 10) + parseInt(suiGasCharges2, 10)) - (parseInt(deficit2, 10) - parseInt(shareCapital2, 10)));
    totalLand = setTotalLand(parseInt(land, 10)+parseInt(developmentExpenditure, 10) + parseInt(externalElectrification, 10));
    totalLand2 = setTotalLand2(parseInt(land2, 10)+parseInt(developmentExpenditure2, 10) + parseInt(externalElectrification2, 10));
    grandTotal3 = setGrandTotal3(parseInt(assets, 10) + parseInt(land, 10)+parseInt(developmentExpenditure, 10) + parseInt(externalElectrification, 10) + parseInt(advance, 10) + parseInt(cash, 10) + parseInt(suspenseAccount, 10));
    grandTotal4 = setGrandTotal4(parseInt(assets2, 10) + parseInt(land2, 10)+parseInt(developmentExpenditure2, 10) + parseInt(externalElectrification2, 10) + parseInt(advance2, 10) + parseInt(cash2, 10) + parseInt(suspenseAccount2, 10));
  
};
const printBalance = () =>{
    window.print();
}
  return (
    <div className="balance-sheet-container">
      <h1 style={{color: 'black'}}>Balance Sheet</h1>

      <div className="balance-section">
        <div className="label-section">
          <div className='balance-section'>
          <label style={{color: 'black', marginRight:'30px'}}>Liabilities:</label>
          <input
          style={{marginRight:'20px', textAlign: 'center'}}
            type="text"
            value={"2023"}
          
          />
          <input
          style={{marginRight:'0px', textAlign: 'center'}}
            type="text"
            value={"2022"}
            
          />
          </div>
          <h1 style={{color: 'black', textAlign: 'left', fontSize: '20px'}}>Share Captial and Reserves</h1>
        </div>
        
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'30px'}}>Assets:</label>
          <input
          style={{marginRight:'20px', textAlign: 'center'}}
            type="text"
            value={"2022"}
            readOnly
          />
          <input
          style={{marginRight:'0px', textAlign: 'center'}}
            type="text"
            value={"2021"}
            readOnly
          />
          </div>
          <h1 style={{color: 'black', textAlign: 'left', fontSize: '20px'}}>Non-Current Assets</h1>
        </div>
      </div>

      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'40px'}}>Share Capital:</label>
          <input
          style={{marginRight:'20px',}}
            type="number"
            value={shareCapital}
            onChange={(e) => setShareCapital(e.target.value )}
          />
          <input
            type="number"
            value={shareCapital2}
            onChange={(e) => setShareCapital2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'15px'}}>Operation Assets:</label>
          <input
           style={{marginRight:'20px'}}
            type="number"
            value={assets}
            onChange={(e) => setAssets(e.target.value )}
          />
          <input
             type="number"
            value={assets2}
            onChange={(e) => setAssets2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'44px'}}>Deficit:</label>
          <input
          style={{marginRight:'20px',}}
             type="number"
            value={deficit}
            onChange={(e) => setDeficit(e.target.value )}
          />
          <input
            type="number"
            value={deficit2}
            onChange={(e) => setDeficit2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
          
         
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'85px'}}></label>
          <input
          style={{marginRight:'20px',}}
             type="number"
            value={totalShareCapital}
            onChange={(e) => setTotalShareCapital(e.target.value )}
          />
          <input
            type="number"
            value={totalShareCapital2}
            onChange={(e) => setTotalShareCapital2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
          
         
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
        
          <h1 style={{color: 'black', textAlign: 'left', fontSize: '20px'}}>Member Deposit</h1>
        </div>
        
        <div className="label-section">
        
          <h1 style={{color: 'black', textAlign: 'left', fontSize: '20px'}}></h1>
        </div>
      </div>

      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'50px'}}>Land Cost:</label>
          <input
          style={{marginRight:'20px',}}
            type="number"
            value={costLand}
            onChange={(e) => setCostLand(e.target.value )}
          />
          <input
            type="number"
            value={costLand2}
            onChange={(e) => setCostLand2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'15px'}}>Development Expenditure:</label>
          <input
           style={{marginRight:'20px'}}
            type="number"
            value={developmentExpenditure}
            onChange={(e) => setDevelopmmentExpenditure(e.target.value )}
          />
          <input
            type="number"
            value={developmentExpenditure2}
            onChange={(e) => setDevelopmmentExpenditure2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'34px'}}>Mosque Fund:</label>
          <input
          style={{marginRight:'20px',}}
            type="number"
            value={mosqueFund}
            onChange={(e) => setMosqueFund(e.target.value )}
          />
          <input
            type="number"
            value={mosqueFund2}
            onChange={(e) => setMosqueFund2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'12px'}}>External electrification:</label>
          <input
           style={{marginRight:'20px'}}
            type="number"
            value={externalElectrification}
            onChange={(e) => setExternalElectrification(e.target.value )}
          />
          <input
             type="number"
            value={externalElectrification2}
            onChange={(e) => setExternalElectrification2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'27px'}}>Sui-Gas Charges:</label>
          <input
          style={{marginRight:'20px',}}
             type="number"
            value={suiGasCharges}
            onChange={(e) => setSuiGasCharges(e.target.value )}
          />
          <input
            type="number"
            value={suiGasCharges2}
            onChange={(e) => setSuiGasCharges2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'62px'}}>Land:</label>
          <input
           style={{marginRight:'20px'}}
            type="number"
            value={land}
            onChange={(e) => setLand(e.target.value )}
          />
          <input
            type="number"
            value={land2}
            onChange={(e) => setLand2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'85px'}}></label>
          <input
          style={{marginRight:'20px',}}
             type="number"
            value={totalMemberDeposit}
            onChange={(e) => setTotalMemberDeposit(e.target.value )}
          />
          <input
            type="number"
            value={totalMemberDeposit2}
            onChange={(e) => setTotalMemberDeposit2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
        <div className="balance-section">
          <label style={{color: 'black', marginRight:'95px'}}></label>
          <input
          style={{marginRight:'20px',}}
          type="number"
          value={totalLand}
          onChange={(e) => setTotalLand(e.target.value )}
          />
          <input
            type="number"
            value={totalLand2}
            onChange={(e) => setTotalLand2(e.target.value )}
          />
          </div>
         
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
        
          <h1 style={{color: 'black', textAlign: 'left', fontSize: '20px'}}></h1>
        </div>
        
        <div className="label-section">
        
          <h1 style={{color: 'black', textAlign: 'left', fontSize: '20px'}}>Current Assets</h1>
        </div>
      </div>

      <div className="balance-section">
        <div className="label-section">
         
          
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'15px'}}>Advance:</label>
          <input
           style={{marginRight:'20px'}}
           type="number"
           value={advance}
           onChange={(e) => setAdvance(e.target.value )}
          />
          <input
            type="number"
            value={advance2}
            onChange={(e) => setAdvance2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'36px'}}>Cash:</label>
          <input
           style={{marginRight:'20px'}}
           type="number"
           value={cash}
           onChange={(e) => setCash(e.target.value )}
          />
          <input
             type="number"
             value={cash2}
             onChange={(e) => setCash2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          
          
        </div>
        
        <div className="label-section">
          
          <div className="balance-section" style={{marginRight:'10px'}}>
          <label style={{color: 'black', marginRight:'12px'}}>Suspense Account:</label>
          <input
           style={{marginRight:'20px'}}
           type="number"
           value={suspenseAccount}
           onChange={(e) => setSuspenseAccount(e.target.value )}
          />
          <input
            type="number"
            value={suspenseAccount2}
            onChange={(e) => setSuspenseAccount2(e.target.value )}
          />
          </div>
        </div>
        
      </div>
      <div className="balance-section">
        <div className="label-section">
          <div className="balance-section">
          <label style={{color: 'black', marginRight:'85px'}}></label>
          <input
          style={{marginRight:'20px',}}
          type="number"
          value={grandTotal}
          onChange={(e) => setGrandTotal(e.target.value )}
          />
          <input
            type="number"
            value={grandTotal2}
            onChange={(e) => setGrandTotal2(e.target.value )}
          />
          </div>
        </div>
        
        <div className="label-section">
        <div className="balance-section">
          <label style={{color: 'black', marginRight:'75px'}}></label>
          <input
          style={{marginRight:'20px',}}
          type="number"
          value={grandTotal3}
          onChange={(e) => setGrandTotal3(e.target.value )}
          />
          <input
             type="number"
             value={grandTotal4}
             onChange={(e) => setGrandTotal4(e.target.value )}
          />
          </div>
         
        </div>
        
      </div>
      <button onClick={handleDownloadPDF}>Calculate</button>
      <button onClick={printBalance}>Get Print</button>
    </div>
  );
};

export default BalanceSheet;
