import React, { useState, useEffect, useRef } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import './printFile.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const MemberLedger = () => {

    const [ledger,setLedger]=useState([]);
    const [plot,setPlot]=useState([]);
  const location = useLocation();
  
  useEffect(() => {
    console.log(location.state.selectedMemberID);
    if (location.state.selectedMemberID) {
      const fetchMemberData = async () => {
        try {
            console.log(location.state.selectedMemberID);
          const response = await Axios.get(`https://backend.pgechs.online/ledger/all/${location.state.selectedMemberID}`);
          if (response.status === 200) {
            const { data } = response;
            if (data.data != null) {
              setLedger(data.data)
              // Use the MemberID as the key to store member details
              console.log(data);
            } else {
              console.log(data.msg);
            }
          } else {
            console.log("No data found from API");
          }
        } catch (error) {
          console.error("Error fetching data from API:", error);
        } finally {
            console.log(ledger);
            fetchPlot();
        }  
      };

      // Call the function to fetch member data
      fetchMemberData();
    } else {
        console.error("Error fetching data from API:");
      } 
  }, [location.state.selectedMemberID]);
  const fetchPlot = async () => {
    try {
        console.log(location.state.selectedMemberID);
      const response = await Axios.get(`https://backend.pgechs.online/plots/all/${location.state.selectedMemberID}`);
      if (response.status === 200) {
        const { data } = response;
        if (data.data != null) {
          setPlot(data.data)
          // Use the MemberID as the key to store member details
          console.log(data);
        } else {
          console.log(data.msg);
        }
      } else {
        console.log("No data found from API");
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
        console.log(ledger);
    }  
  };
const printDocument = () => {
  const input = document.getElementById('divToPrint');
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
};
const handleDownloadPDF = () => {
    window.print();
  };
const [isChecked1, setIsChecked1] = useState(false);
const [isChecked2, setIsChecked2] = useState(false);
const [kanal1, setKanal1] = useState(false);
const [marla10, setMarla10] = useState(false);
const [marla7, setMarla7] = useState(false);
const [marla5, setMarla5] = useState(false);
  const handleCheckBox1Change = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckBox2Change = () => {
    setIsChecked2(!isChecked2);
  };
  const handleKanal = () => {
    setKanal1(!kanal1);
  };

  const handleMarla10 = () => {
    setMarla10(!marla10);
  };
  const handleMarla7 = () => {
    setMarla7(!marla7);
  };
  const handleMarla5 = () => {
    setMarla5(!marla5);
  };
  let sumOfCredit = 0;
  let srNo = 0;
let sumOfDebit = 0;
let remainingBalance = 0;
function DateComponent({ dateString }) {
    // Convert the MongoDB date string to a Date object
    const date = new Date(dateString);
  
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
  
    return <div>{formattedDate}</div>;
  }
  return (
    <div id="divToPrint" className="card">
        <br></br>
      <h2 style={{alignItems:'center', alignContent:'center', alignSelf:'center', color:'black'}}>The Pakistan Government Employees Co-operative Housing Society Ltd.</h2>
      <h2 style={{alignItems:'center', alignContent:'center', alignSelf:'center', color:'black'}}>RGD.NO.266(RAWALPINDI)</h2>
      <h4 style={{alignItems:'center', alignContent:'center', alignSelf:'center', color:'black'}}>Souvenir Arcade, Flat # 209, Second Floor, opposite Zaildar House, near Gulraiz Housing Society, Gate # 02 High Court Road Rawalpindi</h4>
      <p style={{alignItems:'centerLeft', alignContent:'centerLeft', alignSelf:'centerLeft', color:'black'}}>Membership No: {ledger[0]?.MemberID.member_id.MemberId}</p>
      <p style={{alignItems:'centerLeft', alignContent:'centerLeft', alignSelf:'centerLeft', color:'black'}}>Name: {ledger[0]?.MemberID.name}</p>
      <p style={{alignItems:'centerLeft', alignContent:'centerLeft', alignSelf:'centerLeft', color:'black'}}>Plot Size: {plot[0]?.plotType}, {plot[0]?.dimensions}, {plot[0]?.plotID}, {plot[0]?.street}, {plot[0]?.block}</p>
      <p style={{alignItems:'centerLeft', alignContent:'centerLeft', alignSelf:'centerLeft', color:'black'}}>Address: {ledger[0]?.MemberID.address}</p>
       <table border="1">
           <thead>
             <tr>
             <th>S.No.</th> 
               <th>Particulars</th>
               <th>Date</th>
               <th>Slip #</th>
               <th>Debit</th>
               <th>Credit</th>
               <th>Balance</th>
             </tr>
           </thead>
           <tbody>
             {ledger && ledger.map((item, index) => {
               // Save the 'credit' value from a specific index (e.g., index 0)
               srNo++;
               if (true) {
                 sumOfCredit = item.credit + sumOfCredit;
                 sumOfDebit = item.debit + sumOfDebit;
                 remainingBalance = sumOfDebit - sumOfCredit;
               }

               return (
                 <tr key={index}>
                    <td>{srNo}</td>
                   <td>{item.particulars}</td>
                   <td><DateComponent dateString={item.date} /></td>
                   <td>{item.Slip}</td>
                   <td>{item.debit}</td>
                   <td>{item.credit}</td>
                   <td>{remainingBalance}</td>
                 </tr>
               );
             })}
           </tbody>
           
         </table>
         <br></br>
         <button onClick={handleDownloadPDF}>Download PDF</button>
    </div>
  );
};

export default MemberLedger;