import React, { useState, useEffect, useRef } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import '../../Client-management/printFile.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const ExpenditureManagementPrint = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ledger, setLedger] = useState([]);
    const [plot, setPlot] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const response = await Axios.get(`https://backend.pgechs.online/expenditureManagement/all`);
                if (response.status === 200) {
                    const { data } = response;
                    if (data.data != null) {
                        // Use the MemberID as the key to store member details
                        console.log(data);
                        setLedger(data.data);
                    } else {
                        console.log(data.msg);
                    }
                } else {
                    console.log("No data found from API");
                }
            } catch (error) {
                console.error("Error fetching data from API:", error);
            } 
        };
        const fetchAllEmployeeSalaries = async (ledger) => {
            const allSalaries = {};
            for (const item of ledger) {
                try {
                    const response = await Axios.get(`https://backend.pgechs.online/employeeSalary/all/${item._id}`);
                    if (response.status === 200 && response.data.data) {
                        allSalaries[item._id] = response.data.data;
                    }
                } catch (error) {
                    console.error("Error fetching data from API:", error);
                }
            }
            return allSalaries;
        };
        const fetchData = async () => {
            const salaries = await fetchAllEmployeeSalaries(ledger);
            setEmployeeSalaries(salaries);
            setIsLoading(false);
        };

        // Call the function to fetch member data
        fetchMemberData();
        fetchData();
    }, [ledger]);
    const [employeeSalaries, setEmployeeSalaries] = useState({});
    
    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
    };
    const handleDownloadPDF = () => {
        window.print();
    };
    let srNo = 0;
    let bankCharges = 0;
    let bankProfit = 0;
    let whtax = 0;
    let total = 0;
    let grandTotal = 0;
    function DateComponent({ dateString }) {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date
        const formattedDate = `${year}-${month}-${day}`;
        return <div>{formattedDate}</div>;
    }
    const MonthComponent = (dateString) =>  {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date   
        return month;
    }
    const [nameOfEmployee, setNameOfEmployee] = useState([]);
    const [a, setA] = useState(0);
    return (
      <>
       {isLoading=== true ? (<h1>Please wait data is loading</h1>): (
            <div id="divToPrint" className="card">
            <br></br>
            <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>The Pakistan Government Employees Co-operative Housing Society Ltd.</h2>
            <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Tax Year 2023-2024</h2>
            <h4 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Expense Management</h4>
            <table border="2">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Month</th>
                        <th>Bank Profit</th>
                        <th>W.H.Tax</th>
                        <th>Bank Charges</th>
                        <th>W.H.T + B.C</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger && ledger.map((item, index) => {
                        // Save the 'credit' value from a specific index (e.g., index 0)
                        srNo++;
                        
                        if (true) {
                           
                            bankProfit = bankProfit + item.bankProfit;
                            whtax = whtax + item.whTax;
                            bankCharges = bankCharges + item.bankCharges;
                            
                            total = bankCharges + whtax;
                            grandTotal = grandTotal + total;
                        }
                        return (
                            <tr key={index}>
                                <td>{srNo}</td>
                                <td><DateComponent dateString={item.date} /></td>
                                <td>{item.bankProfit}</td>
                                <td>{item.whTax}</td>
                                <td>{item.bankCharges}</td>
                                <td>{total}</td>
                               
                            </tr>
                        ); 
                    })}
                </tbody>
                <tfoot>
             <tr>
               <td>Total:</td>
            
               <td></td>
               <td>{bankProfit}</td>
               <td>{whtax}</td>
               <td>{bankCharges}</td>
               <td>{grandTotal}</td>
              
             </tr>

           </tfoot>
            </table>
            <br></br>
            <button onClick={handleDownloadPDF}>Download PDF</button>
        </div>
        )
       };
      </>
    );
};

export default ExpenditureManagementPrint;