import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import classNames from "classnames";
export default function IncomeForm({ memberDetails, getEditData, editable, getId, getAllCustomers, onHide, memberID, showPopup }) {
    const [errorMEssage, setErrorMessage] = useState(null);
    const [successMEssage, setSuccessMessage] = useState(null);
    const validationSchema = Yup.object().shape({
      income: Yup.string().required('This field is required.'),
      amount: Yup.string().required('This field is required.'),
      date: Yup.date().required('This field is required.'),
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    function DateComponent({ dateString }) {
      // Convert the MongoDB date string to a Date object
      const date = new Date(dateString);
    
      // Extract the date components
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
      const day = String(date.getUTCDate()).padStart(2, '0');
    
      // Format the date
      const formattedDate = `${year}-${month}-${day}`;
    
      return <div>{formattedDate}</div>;
    }
    useEffect(() => {
      if (editable === true) {
        setSelectedOption(getEditData?.head)
        formik.setFieldValue("date", getEditData?.date.substring(0, getEditData?.date.indexOf('T')));
        formik.setFieldValue("income", getEditData?.income);
        formik.setFieldValue("amount", getEditData?.amount);
        
      }
  
    }, [editable]);
    const formik = useFormik({
      initialValues: {
        income: '',
        date: '',
        amount: '',
      },
      onSubmit: async (values) => {
        console.log(selectedOption);
        if (editable === true) {
          const obj = {
            income: selectedOption,
            date: formik.values.date,
            amount: formik.values.amount
          }
          console.log("obj", obj);
          try {
            const res = await Axios.put(`https://backend.pgechs.online/incomeTotal/update/${getEditData?._id}`, obj)
            console.log("putresponse", res)
  
            await getAllCustomers();
            onHide()
  
            console.log("Response success", res.data)
          } catch (error) {
            console.log("Error in Updation" + error)
          }
          getAllCustomers();
        }
        else {
          try {
            const obj = {
              income: selectedOption,
              date: formik.values.date,
              amount: formik.values.amount
            }
            console.log("object has: ", obj);
            // Make a POST request to the backend
            const response = await Axios.post(`https://backend.pgechs.online/incomeTotal/add`, obj, {
              headers: {
                'Content-Type': 'application/json',
              },
              validateStatus: function (status) {
  
                if (status === 200) {
                  getAllCustomers();
                  onHide();
                }
                return status === 200 || status === 409; // Axios will throw an error for other status codes
              },
  
            });
  
            if (response.status === 409) {
              const { data } = response;
              console.log('Error: ' + data.message)
              setErrorMessage(data.message)
            } else if (response.status === 200) {
              const { data } = response;
              console.log('Error: ' + data.message)
              setSuccessMessage(data.message)
              onHide();
              // You can redirect or perform other actions on successful registration
            } else {
              console.log("Unexpected response status:", response.status);
            }
  
          } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error('API Error:', error);
          }
        }
  
      },
    });
    const [selectedOption, setSelectedOption] = useState('');
  
    // Options for the dropdown
    const options = ['Membership Fee', 'Possession Fee', 'Transfer Fee', 'Bank Profit', 'Election Fee', 'Miss. Income', 'Building Approval Fee', 'Deficit', 'Corner Fee'];
  
    // Event handler for option selection
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const onSubmit = () => {
      console.log("Clicked")
  };
    return (
      <div>
        <div className="card">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid grid row">
              <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label>Particulars</label>
                <div className="p-field col-xs-12 col-sm-6 col-md-6  col-lg-6">
                          
                          <select id="dropdown" style={{ paddingLeft: '50px', paddingRight: '50px', fontSize: '16px' }} value={selectedOption} onChange={handleOptionChange}>
                              <option value="">Select Income...</option>
                              {options.map((option, index) => (
                                  <option key={index} value={option}>
                                      {option}
                                  </option>
                              ))}
                          </select>
                      </div>
              </div>
              <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label>Amount</label>
                <InputText
                  id="amount"
                  name="amount"
                  type='number'
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label> Date</label>
                <InputText
                  id="date"
                  type="date"
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                />
  
              </div>
              
  
              <div className="flex cus-buton">
                <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" 
             
                />
              </div>
            </div>
          </form>
        </div>
      </div>
  
    );
}
