import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import classNames from "classnames";
export default function ExpenditureManagementForm({ memberDetails, getEditData, editable, getId, getAllCustomers, onHide, memberID, showPopup }) {

  const [errorMEssage, setErrorMessage] = useState(null);
  const [successMEssage, setSuccessMessage] = useState(null);
  const validationSchema = Yup.object().shape({
    date: Yup.string().required('This field is required.'),
    debit: Yup.string().required('This field is required.'),
    credit: Yup.string().required('This field is required.'),
  });
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  function DateComponent({ dateString }) {
    // Convert the MongoDB date string to a Date object
    const date = new Date(dateString);
  
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
  
    return <div>{formattedDate}</div>;
  }
  useEffect(() => {
    if (editable === true) {
      setSelectedOption(getEditData?.head)
      formik.setFieldValue("date", getEditData?.date.substring(0, getEditData?.date.indexOf('T')));
      formik.setFieldValue("bankProfit", getEditData?.bankProfit);
      formik.setFieldValue("bankCharges", getEditData?.bankCharges);
      formik.setFieldValue("whTax", getEditData?.whTax);
    }

  }, [editable]);
  const formik = useFormik({
    initialValues: {
      bankProfit: '',
      date: '',
      bankCharges: '',
      whTax: '',
    },
    onSubmit: async (values) => {
      console.log(selectedOption);
      if (editable === true) {
        const obj = {
          bankProfit: formik.values.bankProfit,
          date: formik.values.date,
          bankCharges: formik.values.bankCharges,
          whTax: formik.values.whTax,
        }
        console.log("obj", obj);
        try {
          const res = await Axios.put(`https://backend.pgechs.online/expenditureManagement/update/${getEditData?._id}`, obj)
          console.log("putresponse", res)

          await getAllCustomers();
          onHide()

          console.log("Response success", res.data)
        } catch (error) {
          console.log("Error in Updation" + error)
        }
        getAllCustomers();
      }
      else {
        try {
          const obj = {
            bankProfit: formik.values.bankProfit,
            date: formik.values.date,
            bankCharges: formik.values.bankCharges,
            whTax: formik.values.whTax,
          }
          console.log("object has: ", obj);
          // Make a POST request to the backend
          const response = await Axios.post(`https://backend.pgechs.online/expenditureManagement/add`, obj, {
            headers: {
              'Content-Type': 'application/json',
            },
            validateStatus: function (status) {

              if (status === 200) {
                getAllCustomers();
                onHide();
              }
              return status === 200 || status === 409; // Axios will throw an error for other status codes
            },

          });

          if (response.status === 409) {
            const { data } = response;
            console.log('Error: ' + data.message)
            setErrorMessage(data.message)
          } else if (response.status === 200) {
            const { data } = response;
            console.log('Error: ' + data.message)
            setSuccessMessage(data.message)
            onHide();
            // You can redirect or perform other actions on successful registration
          } else {
            console.log("Unexpected response status:", response.status);
          }

        } catch (error) {
          // Handle errors (e.g., show an error message)
          console.error('API Error:', error);
        }
      }

    },
  });
  const [selectedOption, setSelectedOption] = useState('');

  // Options for the dropdown
  const options = ['Repair', 'Photocopy', 'Salary', 'Conveyance', 'Gen.Petrol', 'Advertisement', 'Postage', 'Electricity Bill', 'Development Charges', 'Sui-Gas Bill', 'Land Expenses', 'PTCL Bill', 'Stationary/Printing', 
  'Miscelleneous', 'Legal Fee/Legal Expenses/Tax', 'Entertainment', 'Crokery', 'Office Rent', 'Office Security', 'Refund', 'Stock', 'Bank Charges', 'With Holding Tax', 'News Pape Bill', 'TA&DA Expenses', 'Depreciation', 'Possession of Plot', 'Water Tanker Expenses', 
  'Planation Expenses', 'WAPDA Expenses', 'Cable Bill', 'Audit Fee', 'Election Expenses', 'Income Tax Expenses', 'Honorarioum', 'Maps Consultant Fee', 'AGM Expenses', 'Impress Money/CDR'];

  // Event handler for option selection
  const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
  };
  const onSubmit = () => {
    console.log("Clicked")
};
  return (
    <div>
      <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid p-formgrid p-grid grid row">
            
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Bank Profit</label>
              <InputText
                id="bankProfit"
                name="bankProfit"
                type='number'
                value={formik.values.bankProfit}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>W.H.Tax</label>
              <InputText
                id="whTax"
                name="whTax"
                type='number'
                value={formik.values.whTax}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Bank Charges</label>
              <InputText
                id="bankCharges"
                name="bankCharges"
                type='number'
                value={formik.values.bankCharges}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label> Date</label>
              <InputText
                id="date"
                type="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
              />

            </div>
            

            <div className="flex cus-buton">
              <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" 
           
              />
            </div>
          </div>
        </form>
      </div>
    </div>

  );
}
