import React, { useState, useEffect, useRef } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import './printFile.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const PrintFile = () => {
  const conponentPDF= useRef();
  const location = useLocation();
  console.log(location.state);
  const handleDownloadPDF = () => {
    window.print();
  };
  const generatePDF = useReactToPrint({
    content: ()=>conponentPDF.current,
});
const printDocument = () => {
  const input = document.getElementById('divToPrint');
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
};
const [isChecked1, setIsChecked1] = useState(false);
const [isChecked2, setIsChecked2] = useState(false);
const [kanal1, setKanal1] = useState(false);
const [marla10, setMarla10] = useState(false);
const [marla7, setMarla7] = useState(false);
const [marla5, setMarla5] = useState(false);
  const handleCheckBox1Change = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckBox2Change = () => {
    setIsChecked2(!isChecked2);
  };
  const handleKanal = () => {
    setKanal1(!kanal1);
  };

  const handleMarla10 = () => {
    setMarla10(!marla10);
  };
  const handleMarla7 = () => {
    setMarla7(!marla7);
  };
  const handleMarla5 = () => {
    setMarla5(!marla5);
  };
  return (
    <div id="divToPrint" className="card">
        <br></br>
      <h1 style={{alignItems:'center', alignContent:'center', alignSelf:'center'}}>PGECHS</h1>
      <h2 style={{alignItems:'center', alignContent:'center', alignSelf:'center', color:'black'}}>MEMBERS COPY</h2>
      <div>
      <label>
      Phase 1  
        <input
          type="checkbox"
          checked={isChecked1}
          onChange={handleCheckBox1Change}
        />
        
      </label>
      <br />
      <label>
      Phase 2  
        <input
          type="checkbox"
          checked={isChecked2}
          onChange={handleCheckBox2Change}
        />
        
      </label>
    </div>
    <div>
      <label className="checkbox-label">
        <input
          type="checkbox"
          checked={kanal1}
          onChange={handleKanal}
        />
        1 Kanal
      </label>
      <label className="checkbox-label">
        <input
          type="checkbox"
          checked={marla10}
          onChange={handleMarla10}
        />
        10 Marla
      </label>

      <label className="checkbox-label">
        <input
          type="checkbox"
          checked={marla7}
          onChange={handleMarla7}
        />
        7 Marla
      </label>

      <label className="checkbox-label">
        <input
          type="checkbox"
          checked={marla5}
          onChange={handleMarla5}
        />
        5 Marla
      </label>
    </div>
    <div><h5>Recieved with thanks from:  {location.state.item.MemberID.name}</h5></div>
    <div><h5>Membership No:  {location.state.item.MemberID.member_id.MemberId}</h5></div>
    <table>
        <thead>
          <tr>
            <th>Particulars</th>
            <th>Cheque</th>
            <th>Slip</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Remaining Balance</th>
            <th>Extra Charges</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
        
            <tr >
              
              <td>{location.state.item.particulars}</td>
              <td>{location.state.item.chequeORdraft}</td>
              <td>{location.state.item.Slip}</td>
              <td>{location.state.sumOfDebit}</td>
              <td>{location.state.sumOfCredit}</td>
              <td>{location.state.remainingBalance}</td>
              <td>{location.state.item.extraCharges}</td>
              <td>{location.state.item.date}</td>
            </tr>
        
        </tbody>
      </table>
      <br></br>
      
      <button onClick={handleDownloadPDF}>Get Print</button>
    </div>
  );
};

export default PrintFile;