import React from 'react';
import { Line, Pie } from 'react-chartjs-2';

const dataLine = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'Revenue',
      data: [5000, 7000, 6000, 8000, 9500, 12000],
      borderColor: '#3498db',
      backgroundColor: 'rgba(52, 152, 219, 0.2)',
    },
    {
      label: 'Expenses',
      data: [4000, 5500, 4800, 6000, 7000, 8500],
      borderColor: '#e74c3c',
      backgroundColor: 'rgba(231, 76, 60, 0.2)',
    },
  ],
};

const dataPie = {
  labels: ['Category A', 'Category B', 'Category C', 'Category D'],
  datasets: [
    {
      data: [30, 20, 25, 25],
      backgroundColor: ['#2ecc71', '#3498db', '#f39c12', '#9b59b6'],
    },
  ],
};

const analyticsBoxesData = [
  { title: 'Total Users', value: 1000 },
  { title: 'Active Users', value: 750 },
  { title: 'Revenue', value: '$50K' },
  { title: 'Conversion Rate', value: '20%' },
];

const Accounts = () => {
  return (
    <div>
      <h1 style={{ color: 'black' }}>Account Management</h1>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {analyticsBoxesData.map((box, index) => (
          <div key={index} style={boxStyle}>
            <h3>{box.title}</h3>
            <p>{box.value}</p>
          </div>
        ))}
      </div>

     
    </div>
  );
};

// Styles
const graphContainerStyle = {
  width: '45%',
  padding: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
};

const boxStyle = {
  width: '20%',
  padding: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
};

export default Accounts;
