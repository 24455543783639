import React, { useState, useEffect, useRef } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from 'primereact/button';
import classNames from "classnames";
import Axios from 'axios';
import { Tag } from 'primereact/tag';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import '../../Client-management/printFile.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const AccountHeadPrint = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ledger, setLedger] = useState([]);
    const [plot, setPlot] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const fetchMemberData = async () => {
            try {
                const response = await Axios.get(`https://backend.pgechs.online/incomeStatement/all`);
                if (response.status === 200) {
                    const { data } = response;
                    if (data.data != null) {
                        // Use the MemberID as the key to store member details
                        console.log(data);
                        setLedger(data.data);
                    } else {
                        console.log(data.msg);
                    }
                } else {
                    console.log("No data found from API");
                }
            } catch (error) {
                console.error("Error fetching data from API:", error);
            } 
        };
        const fetchAllEmployeeSalaries = async (ledger) => {
            const allSalaries = {};
            for (const item of ledger) {
                try {
                    const response = await Axios.get(`https://backend.pgechs.online/accountHeadAmount/all/${item._id}`);
                    if (response.status === 200 && response.data.data) {
                        allSalaries[item._id] = response.data.data;
                    }
                } catch (error) {
                    console.error("Error fetching data from API:", error);
                }
            }
            return allSalaries;
        };
        const fetchData = async () => {
            const salaries = await fetchAllEmployeeSalaries(ledger);
            setEmployeeSalaries(salaries);
            setIsLoading(false);
        };

        // Call the function to fetch member data
        fetchMemberData();
        
    }, []);
    const [employeeSalaries, setEmployeeSalaries] = useState({});
    
    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
    };
    const handleDownloadPDF = () => {
        window.print();
    };
    let srNo = 0;
    let sumOfDebit = 0;
    let electionFee = 0;
    let misIncome = 0;
    let msFee = 0;
    let shareMoney = 0;
    let transferFee = 0;
    let pFee = 0;
    let masjidFund = 0;
    let adc = 0;
    let suiGasCharges = 0;
    let month10 = 0;
    let bankProfit = 0;
    let cornerFee = 0;
    let monthTotal1 = 0;
    let monthTotal2 = 0;
    let monthTotal3 = 0;
    let monthTotal4 = 0;
    let monthTotal5 = 0;
    let monthTotal6 = 0;
    let monthTotal7 = 0;
    let monthTotal8 = 0;
    let monthTotal9 = 0;
    let monthTotal10 = 0;
    let monthTotal11 = 0;
    let monthTotal12 = 0;
    let totalSalary = 0;
    let grandTotalSalary = 0;
    let flag = true;
    
    let remainingBalance = 0;
    function DateComponent({ dateString }) {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date
        const formattedDate = `${year}-${month}`;
        return <div>{formattedDate}</div>;
    }
    const MonthComponent = (dateString) =>  {
        // Convert the MongoDB date string to a Date object
        const date = new Date(dateString);
        // Extract the date components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getUTCDate()).padStart(2, '0');
        // Format the date   
        return month;
    }
    const [nameOfEmployee, setNameOfEmployee] = useState([]);
    const [a, setA] = useState(0);
    return (
      <>
   
            <div id="divToPrint" className="card">
            <br></br>
            <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>The Pakistan Government Employees Co-operative Housing Society Ltd.</h2>
            <h2 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Tax Year 2023-2024</h2>
            <h4 style={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', color: 'black' }}>Income Statements</h4>
            <table border="2">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Month</th>
                        <th>Election Fee</th>
                        <th>Misc Income</th>
                        <th>MS Fee</th>
                        <th>Share Money</th>
                        <th>Transfer Fee</th>
                        <th>P Fee</th>
                        <th>Masjid Fund</th>
                        <th>ADC</th>
                        <th>Extra ADC</th>
                        <th>Electricity Charges</th>
                        <th>Sui-Gas Charges</th>
                        <th>Bank Profit</th>
                        <th>Corner Fee</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger && ledger.map((item, index) => {
                        // Save the 'credit' value from a specific index (e.g., index 0)
                        srNo++;
                        totalSalary = item.electionFee +
                        item.misIncome + item.msFee + item.shareMoney
                        + item.transferFee + item.pFee + item.masjidFund
                        + item.ADC + item.extraADC + item.electricityCharges + item.suiGasCharges + item.bankProfit + item.cornerFee;
                        grandTotalSalary = grandTotalSalary + totalSalary;
                        electionFee = electionFee + item.electionFee;
                        misIncome = misIncome + item.misIncome;
                        msFee = msFee + item.msFee;
                        shareMoney = shareMoney + item.shareMoney;
                        transferFee = transferFee + item.transferFee;
                        pFee = pFee + item.pFee;
                        masjidFund = masjidFund + item.masjidFund;
                        adc = adc + item.ADC;
                        suiGasCharges = suiGasCharges + item.suiGasCharges;
                        bankProfit = bankProfit + item.bankProfit;
                        cornerFee = cornerFee + item.cornerFee;
                        return (
                            <tr key={index}>
                                <td>{srNo}</td>
                                <td><DateComponent dateString={item.date} /></td>
                                <td>{item.electionFee}</td>
                                <td>{item.misIncome}</td>
                                <td>{item.msFee}</td>
                                <td>{item.shareMoney}</td>
                                <td>{item.transferFee}</td>
                                <td>{item.pFee}</td>
                                <td>{item.masjidFund}</td>
                                <td>{item.ADC}</td>
                                <td>{item.extraADC}</td>
                                <td>{item.electricityCharges}</td>
                                <td>{item.suiGasCharges}</td>
                                <td>{item.bankProfit}</td>
                                <td>{item.cornerFee}</td>
                                
                                <td>{totalSalary}</td>
                               
                            </tr>
                        ); 
                    })}
                </tbody>
                <tfoot>
             <tr>
               <td>Total:</td>
               
               <td></td>
               <td>{electionFee}</td>
               <td>{misIncome}</td>
               <td>{msFee}</td>
               <td>{shareMoney}</td>
               <td>{transferFee}</td>
               <td>{pFee}</td>
               <td>{masjidFund}</td>
               <td>{adc}</td>
               <td>{adc}</td>
               <td>{adc}</td>
               <td>{suiGasCharges}</td>
               <td>{bankProfit}</td>
               <td>{cornerFee}</td>
               <td>{grandTotalSalary}</td>
             </tr>

           </tfoot>
            </table>
            <br></br>
            <button onClick={handleDownloadPDF}>Download PDF</button>
        </div>
      </>
    );
};

export default AccountHeadPrint;