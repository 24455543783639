import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import classNames from "classnames";
export default function EmployeeSalaryForm({ memberDetails, getEditData, editable, getId, getAllCustomers, onHide, memberID, showPopup }) {

  const [errorMEssage, setErrorMessage] = useState(null);
  const [successMEssage, setSuccessMessage] = useState(null);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.'),
    designation: Yup.string().required('This field is required.'),
    dateOfAppointment: Yup.string().required('This field is required.'),
    dateOfSalary: Yup.string().required('This field is required.'),
    salary: Yup.string().required('This field is required.'),
  });
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  function DateComponent({ dateString }) {
    // Convert the MongoDB date string to a Date object
    const date = new Date(dateString);
  
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
  
    return <div>{formattedDate}</div>;
  }
  useEffect(() => {
    if (editable === true) {
      formik.setFieldValue("name", getEditData?.name);
      formik.setFieldValue("designation", getEditData?.designation);
      formik.setFieldValue("dateOfAppointment", getEditData?.dateOfAppointment.substring(0, getEditData?.dateOfAppointment.indexOf('T')));
    }
    if (showPopup === true) {
      formik.setFieldValue("dateOfSalary", getEditData?.dateOfSalary.substring(0, getEditData?.dateOfSalary.indexOf('T')));
      formik.setFieldValue("salary", getEditData?.salary);
    }
  }, [editable]);
  const formik = useFormik({
    initialValues: {
      name: '',
      designation: '',
      dateOfAppointment: '',
      dateOfSalary: '',
      salary: ''
    },
    onSubmit: async (values) => {
      console.log(selectedOption);
      if (!memberID) {
        if (editable === true) {
          const obj = {
            name: formik.values.name,
            designation: formik.values.designation,
            dateOfAppointment: formik.values.dateOfAppointment,
          }
          console.log("obj", obj);
          try {
            const res = await Axios.put(`https://backend.pgechs.online/employee/update/${getEditData?._id}`, obj)
            console.log("putresponse", res)
  
            await getAllCustomers();
            onHide()
  
            console.log("Response success", res.data)
          } catch (error) {
            console.log("Error in Updation" + error)
          }
          getAllCustomers();
        }
        else {
          try {
            const obj = {
              name: formik.values.name,
              designation: formik.values.designation,
              dateOfAppointment: formik.values.dateOfAppointment,
            }
            console.log("object has: ", obj);
            // Make a POST request to the backend
            const response = await Axios.post(`https://backend.pgechs.online/employee/add`, obj, {
              headers: {
                'Content-Type': 'application/json',
              },
              validateStatus: function (status) {
  
                if (status === 200) {
                  getAllCustomers();
                  onHide();
                }
                return status === 200 || status === 409; // Axios will throw an error for other status codes
              },
  
            });
  
            if (response.status === 409) {
              const { data } = response;
              console.log('Error: ' + data.message)
              setErrorMessage(data.message)
            } else if (response.status === 200) {
              const { data } = response;
              console.log('Error: ' + data.message)
              setSuccessMessage(data.message)
              onHide();
              // You can redirect or perform other actions on successful registration
            } else {
              console.log("Unexpected response status:", response.status);
            }
  
          } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error('API Error:', error);
          }
        }
      } else {
        if (showPopup === true) {
          const obj = {
            dateOfSalary: formik.values.dateOfSalary,
            salary: formik.values.salary
          }
          console.log("obj", obj);
          console.log("putresponse", getEditData?._id);
          try {
            
            const res = await Axios.put(`https://backend.pgechs.online/employeeSalary/update/${getEditData?._id}`, obj)
            console.log("putresponse", res);
            await getAllCustomers();
            onHide();
            console.log("Response success", res.data)
          } catch (error) {
            console.log("Error in Updation" + error)
          }
          getAllCustomers();
        } else {
          try {
            const obj = {
              dateOfSalary: formik.values.dateOfSalary,
              salary: formik.values.salary,
            }
            console.log("object has: ", obj);
            // Make a POST request to the backend
            const response = await Axios.post(`https://backend.pgechs.online/employeeSalary/add/${memberID}`, obj, {
              headers: {
                'Content-Type': 'application/json',
              },
              validateStatus: function (status) {
                if (status === 200) {
                  getAllCustomers();
                  onHide();
                }
                return status === 200 || status === 409; // Axios will throw an error for other status codes
              },
            });
            if (response.status === 409) {
              const { data } = response;
              console.log('Error: ' + data.message)
              setErrorMessage(data.message)
            } else if (response.status === 200) {
              const { data } = response;
              console.log('Error: ' + data.message)
              setSuccessMessage(data.message)
              onHide();
              // You can redirect or perform other actions on successful registration
            } else {
              console.log("Unexpected response status:", response.status);
            }
          } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error('API Error:', error);
          }
        }
      }
    },
  });
  const [selectedOption, setSelectedOption] = useState('');

  // Options for the dropdown
  const options = ['Repair', 'Photocopy', 'Salary', 'Conveyance', 'Gen.Petrol', 'Advertisement', 'Postage', 'Electricity Bill', 'Development Charges', 'Sui-Gas Bill', 'Land Expenses', 'PTCL Bill', 'Stationary/Printing', 
  'Miscelleneous', 'Legal Fee/Legal Expenses/Tax', 'Entertainment', 'Crokery', 'Office Rent', 'Office Security', 'Refund', 'Stock', 'Bank Charges', 'With Holding Tax', 'News Pape Bill', 'TA&DA Expenses', 'Depreciation', 'Possession of Plot', 'Water Tanker Expenses', 
  'Planation Expenses', 'WAPDA Expenses', 'Cable Bill', 'Audit Fee', 'Election Expenses', 'Income Tax Expenses', 'Honorarioum', 'Maps Consultant Fee', 'AGM Expenses', 'Impress Money/CDR'];

  // Event handler for option selection
  const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
  };
  const onSubmit = () => {
    console.log("Clicked")
};
  return (
    <div>
      {!memberID ? <div className="card">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid p-formgrid p-grid grid row">

            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Name</label>
              <InputText
                id="name"
                name="name"
                type='text'
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Designation</label>
              <InputText
                id="designation"
                name="designation"
                type='text'
                value={formik.values.designation}
                onChange={formik.handleChange}
              />
            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label> Date of Appointment</label>
              <InputText
                id="dateOfAppointment"
                type="date"
                name="dateOfAppointment"
                value={formik.values.dateOfAppointment}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex cus-buton">
              <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" 
           
              />
            </div>
          </div>
        </form>
      </div>: <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid p-formgrid p-grid grid row">

            
            <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label> Date of Salary</label>
              <InputText
                id="dateOfSalary"
                type="date"
                name="dateOfSalary"
                value={formik.values.dateOfSalary}
                onChange={formik.handleChange}
              />

            </div>
            <div className="p-field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Salary</label>
              <InputText
                id="salary"
                name="salary"
                type='text'
                value={formik.values.salary}
                onChange={formik.handleChange}
              />
            </div>

            <div className="flex cus-buton">
              <Button label="SUBMIT" className="p-button-rounded p-button-success" type="submit" 
           
              />
            </div>
          </div>
        </form>
        }
    </div>

  );
}
